import React from "react";
// import * as FaIcons from "react-icons/fa";
// import * as FiIcons from "react-icons/fi";
// import i18next from '../../i18n'
import { INDEX_URL, CUST, icons, FLAVOR } from "../../helpers/constants";


const pages = {
    devices: {
        title: "sidebar.devices",
        path: "/devices",
        icon_active: icons.sidebar.device_active,
        icon_inactive: icons.sidebar.device_inactive
    },
    alarms: {
        title: "sidebar.alarms",
        path: "/events",
        icon_active: icons.sidebar.bell_active,
        icon_inactive: icons.sidebar.bell_inactive
    },
    geofence: {
        title: "sidebar.geofence",
        path: "/geofence",
        icon_active: icons.sidebar.geofence_active,
        icon_inactive: icons.sidebar.geofence_inactive
    },
    history: {
        title: "sidebar.history",
        path: "/history",
        icon_active: icons.sidebar.history_active,
        icon_inactive: icons.sidebar.history_inactive
    },
    statistics: {
        title: "sidebar.statistics",
        path: "/statistics",
        icon_active: icons.sidebar.statistics_active,
        icon_inactive: icons.sidebar.statistics_inactive
    },
    subscription: {
        title: "sidebar.subscription",
        path: "/subscriptions",
        icon_active: icons.sidebar.card_active,
        icon_inactive: icons.sidebar.card_inactive
    },
    settings: {
        title: "sidebar.settings",
        path: "/settings",
        icon_active: icons.sidebar.settings_active,
        icon_inactive: icons.sidebar.settings_inactive
    },
}

const bikeData = [
    pages.devices,
    pages.alarms,
    pages.geofence,
    pages.history,
    process.env.REACT_APP_ASSETS !== "rawbike" ? pages.statistics : null,
    pages.subscription,
    pages.settings
].filter(Boolean);

const trackingData = [
    pages.devices,
    pages.alarms,
    pages.geofence,
    pages.history,
    pages.subscription,
    pages.settings
];

const alcoData = [
    pages.devices,
    pages.alarms,
    pages.settings
];

export const SidebarData = FLAVOR === "tracking" ? trackingData : FLAVOR === "bike" ? bikeData : FLAVOR === "alco" ? alcoData : null

