import React, { useState, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { logos, icons, MAIL_FORMATTING } from "../helpers/constants";
import Button from '../components/Buttons/Button';
import LanguagePicker from '../components/LanguagePicker';
import * as request from "../services/fetchService";
import { useNavigate } from 'react-router-dom';
import { useIsMobile } from '../helpers/customHooks';
import TosModal from '../components/Modals/TosModal';
import { useContextData } from '../components/ContextData'; // Adjust the path as necessary

const Signup = (props) => {
    const MOBILE = useIsMobile();
    const navigate = useNavigate()
    const { accessToken, setAccessToken } = useContextData();
	const { t } = useTranslation();
    const [email, setEmail] = useState("")
    const [emailConfirm, setEmailConfirm] = useState("")
    const [password, setPassword] = useState("")
    const [passwordConfirm, setPasswordConfirm] = useState("")
    const [invalidEmail, setInvalidEmail] = useState(false)
    const [invalidEmailConfirm, setInvalidEmailConfirm] = useState(false)
    const [username, setUsername] = useState("")
    const [imei, setImei] = useState("")
    const [policy, setPolicy] = useState(false)
    const [location, setLocation] = useState(false)
    const [step, setStep] = useState(1)
    const [errors, setErrors] = useState({})
	const [modals, setModals] = useState({
		tos: false,
		privacy: false
	})


    useEffect(() => {

    },[])
    
    const handleChange = (type, e) => {
        setErrors({})
        if(type === "email"){
            if(MAIL_FORMATTING.test(e.target.value)){
                setInvalidEmail(false)
            }else{
                setInvalidEmail(true)
            }
            setEmail(e.target.value)
        }else if(type === "emailConfirm"){
            if(MAIL_FORMATTING.test(e.target.value)){
                setInvalidEmailConfirm(false)
            }else{
                setInvalidEmailConfirm(true)
            }
            setEmailConfirm(e.target.value)
        }else if(type === "password"){
            setPassword(e.target.value)
        }else if(type === "passwordConfirm"){
            setPasswordConfirm(e.target.value)

        }else if(type === "username"){
            setUsername(e.target.value)

        }else if(type === "imei"){
            setImei(e.target.value)
        }
    }

	const toggleModal = (modalName) => {
        setModals((prevState) => ({
            ...prevState,
            [modalName]: !prevState[modalName],
        }));
    };

    const validateInputs = (page) => {
        let innerErrors = {}
        if(page === 1){
            innerErrors = {
                email: !MAIL_FORMATTING.test(email),
                email_confirm: !MAIL_FORMATTING.test(emailConfirm),
                email_mismatch: email !== emailConfirm,
                policy: !policy,
                location: !location   
            }
        }else if(page === 2){
            innerErrors = {
                password: password.length<6,
                password_confirm: passwordConfirm.length<6,
                password_mismatch: password !== passwordConfirm
            }
        }else if(page === 3){
            innerErrors = {
                username: username.length<1
            }
        }else if(page === 4){
            innerErrors = {
                imei: imei.length < 10
            }
        }
        setErrors(innerErrors)
        return innerErrors
    }

    const handleNext = (page) => {
       const hasErrors = validateInputs(page)
        if(!Object.values(hasErrors).some(value => value === true) && step < 4){
            setStep(step+1)
        }else if(!Object.values(hasErrors).some(value => value === true) && step === 4){
            createAccount()
        }
    }

    const handleLoginSuccess = async (token) => {
        localStorage.setItem("accesstoken", token)
        await setAccessToken(token)
        navigate("/devices")
    }


    const createAccount = () => {
        let lang = localStorage.getItem("language") ? localStorage.getItem("language") : "en"
        let data = {
            email: email,
            password: password,
            username: username,
            language: lang,
            imeinumber: [parseInt(imei)]
        }
        request.post("", "account/create", data).then((response) => {
            if(response.data.error){
                setErrors((prevState) => ({
                    ...prevState,
                    imei: true
                }))
            }else if(response.data.successful){
                handleLoginSuccess(response.data.accessToken)
            }
        })

    }

    const goBack = () => {
        if(step === 1){
            navigate("/login")
        }else{
            setStep(step-1)
        }
    }

    const step1 = () => {
        return(
        <>  
            <div className='mb-4'>
                <h1 className='mb-2'>1. {t("signup.email")}</h1>
                <p >{t("signup.email_sub")}</p>
            </div>
            <form>
                <div className="form-floating form-floating-group flex-grow-1 is-valid mb-4">
                    <input
                        type="email"
                        placeholder="0"
                        id="email"
                        className="form-control login-input-field pe-4"
                        name="email"
                        value={email}
                        onChange={(e) => {
                            handleChange("email", e);
                        }}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                            e.preventDefault();// Prevent the default Enter key behavior
                            document.getElementById("emailConfirm").focus()
                            }
                        }}
                    />
                    <img className={`position-absolute end-0 bottom-0 mb-2 ${email === "" ? "d-none" : ""}`} src={invalidEmail ? icons.cross : icons.check} alt=""/>
                    <label htmlFor="floatingInput">
                        {t("signup.email")}
                    </label>
                    <div className="invalid-feedback ms-1 bottom">
                    {errors.email ? t("signup.invalid_email") : ""}
                    </div>
                </div>
                <div className="form-floating form-floating-group flex-grow-1 is-valid mb-5">
                    <input
                        type="email"
                        placeholder="0"
                        id="emailConfirm"
                        className="form-control login-input-field pe-4"
                        name="emailConfirm"
                        value={emailConfirm}
                        onChange={(e) => {
                            handleChange("emailConfirm", e);
                        }}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                            e.preventDefault();// Prevent the default Enter key behavior
                            handleNext(1)
                            }
                        }}
                    />
                    <img className={`position-absolute end-0 bottom-0 mb-2 ${emailConfirm === "" ? "d-none" : ""}`} src={invalidEmailConfirm || (email !== emailConfirm) ? icons.cross : icons.check} alt=""/>
                    <label htmlFor="floatingInput">
                        {t("signup.email_confirm")}
                    </label>
                    <div className="invalid-feedback ms-1 bottom">
                        {errors.email_confirm ? t("signup.invalid_email") : errors.email_mismatch ? t("signup.email_mismatch") : ""}
                    </div>
                </div>
            </form>
            <div>
                <div>
                    <div className="d-flex align-items-center justify-content-between">       
                        <p className="thin dark-grey">
                            <Trans
                                i18nKey="signup.policy_tos"
                                components={{
                                policy: <span className="cursor-pointer underline" onClick={() => toggleModal("tos")} />,
                                terms: <span className="cursor-pointer underline" onClick={() => toggleModal("tos")} />,
                                }}
                                values={{
                                policy: t("signup.policy"),
                                terms: t("signup.terms")
                                }}
                            />
                        </p>
                        <div className={`${policy ? "bg-checked" : "bg-grey"} size_20 border-sm d-flex justify-content-center p-1 cursor-pointer`} onClick={() => !policy ? toggleModal("tos") : ""}>
                            {policy && <img className="white-icon" src={icons.check} alt={""} />}
                        </div>
                    </div>
                    <div className="invalid-feedback ms-1 bottom mt-0">
                        {!policy && errors.policy ? t("signup.must_accept_policy") : ""}
                    </div>
                </div>
                <div>
                    <div className="d-flex align-items-center justify-content-between mt-3">
                        <p className="thin dark-grey">{t("signup.location_data")}</p>
                        <div className={`${location ? "bg-checked" : "bg-grey"} size_20 border-sm d-flex justify-content-center p-1 cursor-pointer`} onClick={() => setLocation(!location)}>
                            {location && <img className="white-icon" src={icons.check} alt={""} />}
                        </div>
                    </div>
                    <div className="invalid-feedback ms-1 bottom mt-0">
                        {!location && errors.location ? t("signup.must_accept_location") : ""}
                    </div>
                </div>
            </div>
            <Button btnClass="btn-primary mt-5" onClick={() => handleNext(1)}>{t("signup.next")}</Button>
        </>
        )
    }
    const step2 = () => {
        return(
        <>
            <div className='mb-4'>
                <h1 className='mb-2'>2. {t("signup.password")}</h1>
                <p>{t("signup.password_sub")}</p>
            </div>
            <form>
                <div className="form-floating form-floating-group flex-grow-1 is-valid mb-4">
                    <input
                        type="password"
                        placeholder="0"
                        id="password"
                        className="form-control login-input-field pe-4"
                        name="password"
                        value={password}
                        onChange={(e) => {
                            handleChange("password", e);
                        }}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                            e.preventDefault();// Prevent the default Enter key behavior
                            document.getElementById("passwordConfirm").focus()
                            }
                        }}
                    />
                    <img className={`position-absolute end-0 bottom-0 mb-2 ${password === "" ? "d-none" : ""}`} src={password.length<6 ? icons.cross : icons.check} alt=""/>
                    <label htmlFor="floatingInput">
                        {t("signup.password")}
                    </label>
                    <div className="invalid-feedback ms-1 bottom">
                    {errors.password ? t("signup.invalid_password") : ""}
                    </div>
                </div>
                <div className="form-floating form-floating-group flex-grow-1 is-valid mb-5">
                    <input
                        type="password"
                        placeholder="0"
                        id="passwordConfirm"
                        className="form-control login-input-field pe-4"
                        name="passwordConfirm"
                        value={passwordConfirm}
                        onChange={(e) => {
                            handleChange("passwordConfirm", e);
                        }}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                            e.preventDefault();// Prevent the default Enter key behavior
                            handleNext(2)
                            }
                        }}
                    />
                    <img className={`position-absolute end-0 bottom-0 mb-2 ${passwordConfirm === "" ? "d-none" : ""}`} src={password.length<6 || (password !== passwordConfirm) ? icons.cross : icons.check} alt=""/>
                    <label htmlFor="floatingInput">
                        {t("signup.password_confirm")}
                    </label>
                    <div className="invalid-feedback ms-1 bottom">
                        {errors.password_confirm ? t("signup.invalid_password") : errors.password_mismatch ? t("signup.password_mismatch") : ""}
                    </div>
                </div>
            </form>
            <Button btnClass="btn-primary mt-5" onClick={() => handleNext(2)}>{t("signup.next")}</Button>
        </>
        )
    }

    const step3 = () => {
        return(
        <>
            <div className='mb-4'>
                <h1 className='mb-2'>3. {t("signup.account_details")}</h1>
                <p>{t("signup.account_details_sub")}</p>
            </div>
            <form>
                <div className="form-floating form-floating-group flex-grow-1 is-valid mb-4">
                    <input
                        type="text"
                        placeholder="0"
                        id="username"
                        className="form-control login-input-field pe-4"
                        name="username"
                        value={username}
                        onChange={(e) => {
                            handleChange("username", e);
                        }}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                            e.preventDefault();// Prevent the default Enter key behavior
                            handleNext(3)
                            }
                        }}
                    />
                    <label htmlFor="floatingInput">
                        {t("signup.username")}
                    </label>
                    <div className="invalid-feedback ms-1 bottom">
                    {errors.username ? t("signup.invalid_username") : ""}
                    </div>
                </div>
            </form>
            <Button btnClass="btn-primary mt-5" onClick={() => handleNext(3)}>{t("signup.next")}</Button>
        </>
        ) 
    }

    const step4 = () => {
        return(
            <>
                <div className='mb-4'>
                    <h1 className='mb-2'>4. {t("signup.imei")}</h1>
                    <p>{t("signup.imei_sub")}</p>
                </div>
                <form>
                    <div className="form-floating form-floating-group flex-grow-1 is-valid mb-4">
                        <input
                            type="text"
                            placeholder="0"
                            id="imei"
                            className="form-control login-input-field pe-4"
                            name="imei"
                            value={imei}
                            onChange={(e) => {
                                handleChange("imei", e);
                            }}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                e.preventDefault();// Prevent the default Enter key behavior
                                }
                            }}
                        />
                        <label htmlFor="floatingInput">
                            {t("signup.imei")}
                        </label>
                        <div className="invalid-feedback ms-1 bottom">
                        {errors.imei ? t("signup.invalid_imei") : ""}
                        </div>
                    </div>
                </form>
                <Button btnClass="btn-primary mt-5" onClick={() => handleNext(4)}>{t("signup.create_account")}</Button>
            </>
            ) 
    }

    return(
        <>
        <div className="container-fluid h-100">
            <div className="d-flex flex-wrap align-content-center justify-content-center h-100">
                    <div className={`d-flex flex-column justify-content-between h-75 col-10 col-md-6 col-xl-4`}>
                            <img className="login_logo mx-auto mb-5" src={logos.big} alt="logo"/>
                            {
                                step === 1 
                                ? step1()
                                : step === 2
                                ? step2()
                                : step === 3
                                ? step3()
                                : step === 4
                                ? step4()
                                : null
                            }
                            <div className='d-flex justify-content-center mt-3'>
                                <img className="me-2" src={step >= 1 ? icons.dot_selected : icons.dot_unselected} alt=""/>
                                <img className="me-2" src={step >= 2 ? icons.dot_selected : icons.dot_unselected} alt=""/>
                                <img className="me-2" src={step >= 3 ? icons.dot_selected : icons.dot_unselected} alt=""/>
                                <img src={step >= 4 ? icons.dot_selected : icons.dot_unselected} alt=""/>
                            </div>
                    </div>
            </div>
        </div>
        <div className='position-absolute left-0 top-0 ms-4 mt-4 ms-lg-5 mt-lg-5'>
            <Button btnClass="btn-left-section" className="align-self-start" icon={icons.back} notext={true} onClick={() => goBack()}/>
        </div>
        <div className={`position-absolute left-0 bottom-0 ms-4 mb-4 ms-lg-5 mb-lg-5 bg-grey cursor-pointer rounded`}>
            <LanguagePicker />
        </div>
        <TosModal show={modals.tos} accept={() => setPolicy(true)} onClose={() => toggleModal("tos")} />
        </>
    )
}

export default Signup;