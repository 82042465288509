import React, { useState } from 'react';
import { useTranslation } from "react-i18next";

import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import Button from '../../Buttons/Button';
import * as request from "../../../services/fetchService";
import toast from 'react-hot-toast';

const CARD_ELEMENT_OPTIONS = {
	hidePostalCode: true,
	iconStyle: "solid",
	disableLink: true,
	style: {
		base: {
			color: "#000000",
			iconColor: "rgb(108, 117, 125)",
			fontFamily: '"Roboto", Roboto, sans-serif',
			fontSmoothing: "antialiased",
			fontSize: "16px",
			"::placeholder": {
				color: "rgb(108, 117, 125)",
				iconColor:"rgb(108, 117, 125)",
			},
		},
		invalid: {
			color: "#fa755a",
			iconColor: "#fa755a",
		}
	}
};
const AddCardInterim = (props) => {
    const { t } = useTranslation();
	const elements = useElements();
	const stripe = useStripe();
	const [loading, setLoading] = useState(false);

	const handleSubmit = async (event) => {
		event.preventDefault();
		setLoading(true)
		if(!stripe || !elements){
			return;
		}
		const cardElement = elements.getElement(CardElement);
		const tokenResponse = await stripe.createToken(cardElement);
		const UserEmail = localStorage.getItem("email");

		if (tokenResponse.hasOwnProperty("error")) {
			setLoading(false)
			toast.error(tokenResponse.error.message)
			return;
		}
		const Data = {"cardobject": tokenResponse.token}
		const email = UserEmail
		const cardHolder = document.getElementById("cardholder").value
		const paymentMethod = await stripe.createPaymentMethod({
			type: 'card',
			card: cardElement,
			billing_details: {
				name: cardHolder
			}
		})
		let cardToken = tokenResponse.token.id
		let createResponse = await request.post("", "dataplan/cards/create", { cardtoken: cardToken, payment_method: paymentMethod })
		if(createResponse.status === 200){
			const setupIntentResponse = await stripe.retrieveSetupIntent(createResponse.data.client_secret)
			if(setupIntentResponse.hasOwnProperty("error")) {
				setLoading(false)
				toast.error(setupIntentResponse.error.message);
				return;
			  }else{
				const confirmCardResponse = await stripe.confirmCardSetup(setupIntentResponse.setupIntent.client_secret)
				if(confirmCardResponse.hasOwnProperty("error")){
					setLoading(false)
					toast.error(confirmCardResponse.error.message);
				  return;
				}else if(confirmCardResponse.setupIntent.status === "succeeded"){
					request.post("", "dataplan/cards/primary", {payment_method: confirmCardResponse.setupIntent.payment_method}).then((response) => {
						if(response.data.successful){
							props.updateCards()
							toast.success(t("toast.success"))
							setLoading(false)
						}
					})
				}else{
					setLoading(false)
				  toast.error(confirmCardResponse.error.message);
				  return;
				}
			  }
		}else{
			setLoading(false)
			toast.error(createResponse.data.message)
		}
		// request.post("", "dataplan/cards/create", {payment_method: paymentMethod}).then((response) => {
		// })
        /*
		dataplanService.createCard(props.token, Data, email, paymentMethod).then(response => {
			if(response.error){
				setLoading(false)
			}else{
				dataplanService.retrieveSetupIntent(response.client_secret).then(response => {
					stripe.confirmCardSetup(response.setupIntent.client_secret).then(function(result) {
						if(result.setupIntent.status === "succeeded"){
							dataplanService.SetnewDefaultCard(props.token, result.setupIntent.payment_method).then(response => {})
							setLoading(false)
							props.close()
						}else{
							setLoading(false)
						}
					});
				})
			}
		})
        */
	}
	return (
		<>
			<form onSubmit={handleSubmit}>
                <div className="w-100">
                    <input
						id="cardholder"
                        type="text"
                        className="form-control input_box stripe-input p-2"
						//style={{fontSize: "0.9rem"}}
                        placeholder={t("subscription.cardholder_name")}
                    />
                </div>
                <div className="w-100 mt-3" id="theElement">
                    <CardElement 
                        className="form-control input_box stripe-input card-element-inp p-2 w-100" 
                        options={CARD_ELEMENT_OPTIONS}
                        style={{paddingTop: "10px"}}
                    />
                </div>
                <Button btnClass="btn-primary gradient-button-primary mt-3" type="submit">{t("subscription.save")}<span className={`${loading ? "" : "d-none"} ms-2 size_15 spinner-grow`}/></Button>
			</form>
		</>
	)
}
export default AddCardInterim;
