import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import * as request from "../services/fetchService";

const LanguagePicker = (props) => {
    const { t, i18n } = useTranslation();

    const [showLanguages, setShowLanguages] = useState(false)
    const [active, setActive] = useState(i18n.language)

    useEffect(() => {
        getLanguage()
    }, [])

    const getLanguage = () => {
        const navLang = navigator.language.split("-");
        const i18Lang = i18n.language.split("-")[0];
        if(localStorage.getItem("language")){
            setActive(localStorage.getItem("language"))
            return
        }else{
            if(i18n.options.whitelist.includes(navLang)){
                setActive(navLang)
            }
        }
    }

    const postNewLanguage = (lang) => {
        request.post("", "notification/updatelanguages", {languages: lang}).then((response) => {
        })
    }

    useEffect(() => {

        if(localStorage.getItem("accesstoken") && active !== i18n.language){
            postNewLanguage(active)
        }
        localStorage.setItem("language", active);
        i18n.changeLanguage(active);
        document.documentElement.setAttribute("lang", active);
        setShowLanguages(false);
    }, [active])

    const updateLang = (lang) => {
        if(lang === active){
            setShowLanguages(false);
        }else{
            setActive(lang) 
        }
    }

    useEffect(() => {
        function handleClickOutside(event) {
            if (!event.target.closest('.language_popup') && !event.target.closest('.lang-btn')) {
                setShowLanguages(false);
            }  
        }
    
        // Add event listener when component mounts
        document.addEventListener("mousedown", handleClickOutside);
        
        // Clean up the event listener when component unmounts
        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }, [showLanguages]);



    return(
        <>
        <div className="lang-btn uppercase p bold py-2" onClick={() => setShowLanguages(!showLanguages)}>
            {active}
        </div>
        
        <div className={`language_popup ${showLanguages ? "lang_show" : ""}`}>
            <div className="lang_popup_window p-2 d-flex flex-wrap">
                {i18n.options.whitelist.map((lang, index) => (
                    <p key={index} onClick={(e) => updateLang(lang)} className={`lang_pill py-1 px-2 mx-1 my-1 uppercase ${active === lang ? "lang_selected" : ""}`}>
                        {lang}
                    </p>
                ))}
            </div>
        </div>
        
        </>
    )
}

export default LanguagePicker;