import React, { useState, useEffect } from 'react';
import { INDEX_URL, CUST, icons, markers, FLAVOR } from "../../helpers/constants";
import { useTranslation } from "react-i18next";
import * as request from "../../services/fetchService";
import { fixDate, getMapMarker, staticSort } from '../../helpers/helperFunctions';
import Button from '../Buttons/Button';
import Searchbar from '../Searchbar/Searchbar';
import AddDevice from "../../components/Modals/AddDevice";
import ShareDevice from "../../components/Modals/ShareDevice";
import { useContextData } from '../../components/ContextData';
import DeviceModal from '../Modals/DeviceModal';
import { Collapse } from '@mui/material';
import { useIsMobile } from '../../helpers/customHooks';
import toast from 'react-hot-toast';

const Devices = (props) => {
    const MOBILE = useIsMobile();
	const { t } = useTranslation();
    const { deviceListCompact, setDeviceListCompact } = useContextData();
    const [myDevicesFull, setMyDevicesFull] = useState([])
    const [myDevices, setMyDevices] = useState([])
    const [sharedDevicesFull, setSharedDevicesFull] = useState([])
    const [sharedDevices, setSharedDevices] = useState([])
    const [pendingInvites, setPendingInvites] = useState([])
    const [addDevice, setAddDevice] = useState(false)
    const [shareDevice, setShareDevice] = useState(false)
    const [allDevices, setAllDevices] = useState([])
    const [openDevice, setOpenDevice] = useState()
    const [currentIcon, setCurrentIcon] = useState({})
    const [showModal, setShowModal] = useState(false)
    const [loading, setLoading] = useState(true)
    const [type, setType] = useState()
    const [centrals, setCentrals] = useState([])
    useEffect(() => {
        getDevices()
        getCentralInfo()
    }, [])

    const getCentralInfo = () => {
        request.get("alarmcenter/central/info").then((response) => {
            setCentrals(response.data.response)
        })
        
    }

    const getDevices = () => {
        request.get("devices/compactinfo").then((response) => {
            setDeviceListCompact(response.data.devices)
            const devices = response.data.devices
            const pending = response.data.pendingSharedDevices
            const shared = devices.filter((device) => device.sharedstatus.sharedwithme)
            const mine = devices.filter((device) => !device.sharedstatus.sharedwithme)
            let sortedShared = staticSort(shared, "name")
            let sortedMine = staticSort(mine, "name")
            setAllDevices(devices)
            setMyDevices(sortedMine)
            setMyDevicesFull(sortedMine)
            setSharedDevices(sortedShared)
            setSharedDevicesFull(sortedShared)
            setPendingInvites(pending)
            setLoading(false)
        }) 
    }

    const filterSearch = (searchWord) => {
			if(!searchWord || searchWord === ""){
                setMyDevices(myDevicesFull)
                setSharedDevices(sharedDevicesFull)
				return
			}
            let arrayMy = myDevicesFull;
			let matchMy = arrayMy.filter((device) => {
			const item = searchWord.toLowerCase();
			return (
				device.name.toLowerCase().indexOf(item) > -1 ||
				device.uniqueid.toLowerCase().indexOf(item) > -1
			  );
			})
            let arrayShared = sharedDevicesFull
            let matchShared = arrayShared.filter((device) => {
            const item = searchWord.toLowerCase();
            return (
                device.name.toLowerCase().indexOf(item) > -1 ||
                device.uniqueid.toLowerCase().indexOf(item) > -1
                );
            })
			setMyDevices(matchMy)
			setSharedDevices(matchShared)
	}

    useEffect(() => {
        if(openDevice){
            setCurrentIcon(
                {
                    icon: openDevice.mapicon.split("_")[0],
                    color: openDevice.mapicon.split("_")[1]
                }
            )
            props.setIsOpen(true)
        }
    }, [openDevice])

    const openModal = (type) => {
        if(type === "imei" || type === "battery"){
            return
        }
        setType(type)
        setShowModal(!showModal)
    }

    useEffect(() => {
        setOpenDevice()
        props.setIsOpen(false)
    }, [props.closeDevice])

    const saveData = (data, reset = false) => {
        request.post("", "device/update-new", data).then((response) => {
            if(response.data?.successful){
                if(!reset){
                    toast.success(t("toast.success"))
                    setShowModal(!showModal)
                }
                getDevices()
            }else{
                toast.error(t("toast.error"))
            }
        })
    }

    useEffect(() => {
        if(openDevice){
            let updatedDevice = allDevices.find(device => device.id === openDevice.id)
            setOpenDevice(updatedDevice)
        }
    }, [allDevices, openDevice])

    const removeShared = (pending, id, email) => {
        if(pending){
            request.post("", "device/share/revokependinginvitation", {deviceid: [openDevice.id], email: [email]}).then((response) => {
                if(response.data){
                    toast.success(t("toast.success"))
                    getDevices()
                }
            })
            
        }else{
            request.post("", "device/delete", {deviceid: [openDevice.id], sharedid: [id]}).then((response) => {
                if(response.data){
                    toast.success(t("toast.success"))
                    getDevices()
                }
            })  
        }
    }

    const deleteData = (type) => {
        let url;
        let id;
        switch(type){
            case "delete_history":
                url = "history/delete"
                id = openDevice.id
                break;
            case "delete_device":
                url = "device/delete"
                id = [openDevice.id]
                break;
            default: return
        }
        request.post("", url, {deviceid: id}).then((response) => {
            if(response.data){
                setShowModal(!showModal)
                getDevices()
                setOpenDevice()
                toast.success(t("toast.done"))
            }
        })
    }

    const info = [
        {
            valid: openDevice && (FLAVOR === "bike" ? openDevice.communicationmode.vehiclebattery.valid : openDevice.visibleswitch?.editdevice.battery_external),
            title: t("settings.battery_external"),
            data: openDevice && (FLAVOR === "bike" ? `${openDevice.communicationmode.vehiclebattery.info}%` : `${openDevice.modelspecificinfo.externalbattery.externalbatteryinfo} V`)
        },
        {
            valid: openDevice && (FLAVOR === "bike" ? openDevice.communicationmode.devicebattery.valid : openDevice.visibleswitch?.editdevice.battery_internal),
            title: t("settings.battery_internal"),
            data: openDevice && (FLAVOR === "bike" ? `${openDevice.communicationmode.devicebattery.info}%` : `${openDevice.modelspecificinfo.internalbattery.internalbatteryinfo ? openDevice.modelspecificinfo.internalbattery.internalbatteryinfo : 0}%`)
        },
        {
            valid: openDevice && (FLAVOR === "bike" ? false : openDevice.visibleswitch?.editdevice.temperature) ,
            title: t("settings.temperature"),
            data: openDevice && (FLAVOR === "bike" ? null : `${(openDevice.modelspecificinfo.temp_hum?.currentdata.temperature/100).toFixed(1)} ${openDevice.modelspecificinfo.temp_hum?.currentsettings.tempType === 1 ? "°F" : "°C"}`)
        },
        {
            valid: openDevice && (FLAVOR === "bike" ? false : openDevice.visibleswitch?.editdevice.humidity),
            title: t("settings.humidity"),
            data: openDevice && (FLAVOR === "bike" ? null : `${(openDevice.modelspecificinfo.temp_hum?.currentdata.humidity/100).toFixed(1)}%`)
        },

    ]
    const options = [
        {
            name: "devicename",
            valid: openDevice && (FLAVOR === "bike" ? true : openDevice.visibleswitch?.editdevice.name),
            title: t("settings.name"),
            data: openDevice && openDevice.name,
            icon: icons.dots_vertical
        },
        {
            name: "imei",
            valid: openDevice && (FLAVOR === "bike" ? true : openDevice.visibleswitch?.editdevice.imei),
            title: FLAVOR === "bike" ? t("settings.vin") : t("settings.imei"),
            data: openDevice && (FLAVOR === "bike" ? openDevice.vinnumber : openDevice.uniqueid) 
        },
        {
            name: "speedlimit",
            valid: openDevice && (FLAVOR === "bike" ? true : openDevice.visibleswitch?.editdevice.alarm_speed),
            title: t("settings.speed_settings"),
            data: openDevice && openDevice.modelspecificinfo.speedlimit.speedlimitinfo ? `${openDevice.modelspecificinfo.speedlimit.speedlimitinfo} ${openDevice.modelspecificinfo.speedlimit.speedlimitunit}` : t("settings.not_set"),
            icon: icons.dots_vertical
        },
        {
            name: "map_icon",
            valid: openDevice && (FLAVOR === "bike" ? false : openDevice.visibleswitch?.editdevice.mapicon),
            title: t("settings.map_icon"),
            data: openDevice && getMapMarker(openDevice?.mapicon, true),
            icon: icons.dots_vertical,
            img: true
        },
        {
            name: "powersaving",
            valid: openDevice && (FLAVOR === "bike" ? false : openDevice.visibleswitch?.editdevice.powersavingmode) ,
            title: t("settings.powersaving"),
            icon: icons.dots_vertical

        },
        {
            name: "wakeup",
            valid: openDevice && (FLAVOR === "bike" ? false : openDevice.visibleswitch?.editdevice.wakeupmode),
            title: t("settings.wakeup"),
            icon: icons.dots_vertical
        },
        {
            name: "temperature",
            valid: openDevice && (FLAVOR === "bike" ? false : openDevice.visibleswitch?.editdevice.alarm_temp),
            title: t("settings.temperature"),
            icon: icons.dots_vertical
        },
        {
            name: "humidity",
            valid: openDevice && (FLAVOR === "bike" ? false : openDevice.visibleswitch?.editdevice.alarm_hum),
            title: t("settings.humidity"),
            icon: icons.dots_vertical
        },
        {
            name: "alarm_central",
            valid: openDevice && (FLAVOR === "bike" ? false : openDevice.visibleswitch?.editdevice.alarmcentral),
            title: t("settings.alarm_central"),
            icon: icons.dots_vertical
        },
        {
            name: "sharing",
            valid: openDevice && (FLAVOR === "bike" ? true : openDevice.visibleswitch?.editdevice.sharing),
            title: t("settings.sharing_status"),
            icon: icons.right_arrow_dark
        },
        {
            name: "delete_history",
            valid: openDevice && (FLAVOR === "bike" ? true : openDevice.visibleswitch?.editdevice.delete),
            title: t("settings.delete_history"),
            danger: true,
            icon: icons.right_arrow_dark
        },
        {
            name: "delete_device",
            valid: openDevice && (FLAVOR === "bike" ? true : openDevice.visibleswitch?.editdevice.delete),
            title: t("settings.delete_device"),
            danger: true,
            icon: icons.right_arrow_dark
        },
    ]

    const validOptions = () => {
        return options.filter(option => option.valid)
    }

    const validInfo = () => {
        return info.filter(info => info.valid)
    }

    const handleInvite = (accept, id) => {
        request.post("", "device/share/acceptdenyinvitation", {deviceid: [id], accept: accept}).then((response) => {
            if(response.data.successful){
                getDevices()
            }else{
                alert("error")
            }
        })
    }


    return(
        <>
        {!openDevice
            ?
            <>
            <div className="d-flex flex-column">
            <div>
                <Searchbar submitSearch={(data) => filterSearch(data)}/>
                
                <p className="thin mt-3 mb-1">{t("settings.my_devices")}</p>
                <div className="d-flex flex-column">
                    <div className={`scrollable-y shadow-small settings_device_list primary`}>
                    {myDevices && myDevices.map((device, index) => (
                        <div key={index} className={`p-3 w-100 d-flex flex-column bg-white ${index < myDevices.length-1 ? "border-settings" : ""} cursor-pointer`} onClick={() => setOpenDevice(device)}>
                            <div className="d-flex justify-content-between align-items-center">
                                <span className="regular">{device.name}</span>
                                <img className="size_15" src={icons.right_arrow_dark} alt={""} />
                            </div>
                        </div>
                    ))}
                    </div>
                    {myDevices && myDevices.length === 0 && !loading &&
                        <div className={`p-3 w-100 d-flex flex-column bg-transparent`}>
                           <span className="regular">{t("settings.no_devices")}</span>
                        </div>
                    }
                </div>
            </div>
            <p className="thin mt-3 mb-1">{t("settings.shared_devices")}</p>
            <div className="d-flex flex-column">
                <div className={`scrollable-y shadow-small settings_device_list primary`}>
                {loading &&
                    <div className={`p-3 w-100 d-flex flex-column placeholder-glow`} >
                        <div className="d-flex justify-content-between align-items-center">
                            <span className="regular placeholder w-50"></span>
                        </div>
                    </div>
                }
                {sharedDevices && sharedDevices.map((device, index) => (
                    <div key={index} className={`p-3 w-100 d-flex flex-column bg-white ${index < sharedDevices.length-1 ? "border-settings" : ""} cursor-pointer`} onClick={() => setOpenDevice(device)}>
                        <div className="d-flex justify-content-between align-items-center">
                            <span className="regular">{device.name}</span>
                            <img className="size_15" src={icons.right_arrow_dark} alt={""} />
                        </div>
                    </div>
                ))}
                </div>
                {sharedDevices && sharedDevices.length === 0 && !loading &&
                    <div className={`p-3 w-100 d-flex flex-column bg-transparent`}>
                        <span className="regular">{t("settings.no_devices")}</span>
                    </div>
                }
            </div>
            <p className={`thin mt-3 mb-1 ${pendingInvites.length<1 ? "d-none" : ""}`}>{t("settings.device_invitations")}</p>
            <div className="d-flex flex-column">
                <div className={`scrollable-y shadow-small settings_device_list primary`}>
                {pendingInvites && pendingInvites.map((device, index) => (
                    <div key={index} className={`p-3 w-100 d-flex flex-column bg-white ${index < pendingInvites.length-1 ? "border-settings" : ""}`}>
                        <div className="d-flex justify-content-between align-items-center">
                            <span className="regular">{t("settings.invitation_sub", {from: device.sharedstatus.sharedfrom, device: device.name})}</span>
                            <div>
                                <img className="size_15 me-3 cursor-pointer" src={icons.check} alt={""} onClick={() => handleInvite(true, device.id)}/>
                                <img className="size_15 cursor-pointer" src={icons.cross} alt={""} onClick={() => handleInvite(false, device.id)}/>
                            </div>
                        </div>
                    </div>
                ))}
                </div>
            </div>
            <p className="thin mt-3 mb-1">{t("settings.add_share")}</p>         
            <div className="d-flex flex-column shadow-small settings-radius bg-white">
                <div className="p-3 d-flex justify-content-between align-items-center border-settings cursor-pointer" onClick={() => setAddDevice(!addDevice)}>
                    <span className="regular">{t("settings.add_device")}</span>
                    <img className="size_15" src={icons.plus_grey} alt={""} />
                </div>
                <div className="p-3 d-flex justify-content-between align-items-center cursor-pointer" onClick={() => setShareDevice(!shareDevice)}>
                    <span className="regular">{t("settings.share_device")}</span>
                    <img className="size_15" src={icons.share_grey} alt={""} />
                </div>
            </div>
        </div>
        <AddDevice show={addDevice} onClose={() => setAddDevice(!addDevice)} updateDevices={() => getDevices()} />
        <ShareDevice show={shareDevice} updateDevices={() => getDevices()} devices={myDevicesFull} onClose={() => setShareDevice(!shareDevice)} />
        </>
        :
        <>
        <div className="d-flex flex-column mb-5">
            {!MOBILE && <Button btnClass="btn-left-section" className="align-self-start" icon={icons.back} notext={true} onClick={() => setOpenDevice()}/>}
            <h3 className="my-2">{openDevice.name}</h3>
            <p className="thin mb-1 ">{t("settings.edit_device")}</p>
            <div className="d-flex flex-column shadow-small settings-radius">
                {validOptions().map((item, index) => (
                    <div key={index} className={`p-3 d-flex justify-content-between bg-white ${index < validOptions().length-1 ? "border-settings" : ""}`}>
                        <span className={`regular ${item.danger ? "text-danger" : ""}`}>{item.title}</span>
                        <div className={`d-flex align-items-center ${item.icon ? "cursor-pointer" : ""}`} onClick={() => openModal(item.name)}>
                            {item.img ? <img className="size_25" src={item.data} alt=""/> : <p className="bold">{item.data}</p>}
                            {item.icon &&
                                <img className="ms-3 size_15" src={item.icon} alt={""} />
                            }
                        </div>
                    </div>
                ))}
            </div>

            <p className="thin mb-1 mt-3">{t("settings.device_info")}</p>
            <div className="d-flex flex-column shadow-small settings-radius">
                {validInfo().map((item, index) => (
                    <div key={index} className={`p-3 d-flex justify-content-between bg-white ${index < validInfo().length-1 ? "border-settings" : ""}`}>
                        <span className={`regular ${item.danger ? "text-danger" : ""}`}>{item.title}</span>
                        <div className={`d-flex align-items-center`}>
                            <p className="bold">{item.data}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
        <DeviceModal show={showModal}  centrals={centrals} deleteData={(data) => deleteData(data)} removeShared={(pending, id, email) => removeShared(pending, id, email)} onSave={(data, reset) => saveData(data, reset)} type={type} data={openDevice} onClose={() => setShowModal(!showModal)}/>
        </>
        }
       
        </>
    )
}

export default Devices;