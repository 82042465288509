import React, { useState, useEffect } from 'react';
import { useIsMobile } from '../../helpers/customHooks';
import { useTranslation } from "react-i18next";
import { INDEX_URL, CUST, icons, STRIPE_TEST_KEY } from "../../helpers/constants";
import MonthYearSegment from '../PeriodSegment/PeriodSegement';
import Button from '../Buttons/Button';
import DeviceActivationList from '../Devices/DeviceActivationList';
import { Collapse } from '@mui/material';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import AddCard from './Stripe/AddCard';
import CardRow from './Stripe/CardRow';
import SubscriptionModal from '../Modals/SubscriptionModal';
import * as request from "../../services/fetchService";
import { useStripe } from "@stripe/react-stripe-js";
import EditCardModal from '../Modals/EditCardModal';


const stripePromise = loadStripe(STRIPE_TEST_KEY);

const Activating = (props) => {
    const { t } = useTranslation();
	const MOBILE = useIsMobile();
	const [showModal, setShowModal] = useState(false)
	const [type, setType] = useState("")
	const pricing = require(`./Stripe/Pricing/${process.env.REACT_APP_ASSETS}/pricelist.json`);
	const [country, setCountry] = useState(props.cards && props.cards.length > 0 ? props.cards[0].card.country : "SE")
	const [paymentType, setPaymentType] = useState("")
	const [thinking, setThinking] = useState(false)
    const [cards, setCards] = useState(props.cards ? props.cards : [])
	const [dots, setDots] = useState('')
	const [step, setStep] = useState(1)
	const [ssf, setSsf] = useState(false)
	const [selectedPlan, setSelectedPlan] = useState("")
	const [allSelected, setAllSelected] = useState(false)
	const [period, setPeriod] = useState(false)
	const [activatableDevices, setActivatableDevices] = useState(props.devices)
	const [showCard, setShowCard] = useState(false)
	const stripe = useStripe();

	useEffect(() => {
		setCards(props.cards ? props.cards : [])
	}, [props.cards])

	const purchaseDone = () => {

		setPaymentType("")
		setStep(1)
		setSsf(false)
		setSelectedPlan("")
		setPeriod(false)
		setAllSelected(false)
		if (!allSelected) {
			let updatedDevices = activatableDevices ? [...activatableDevices] : [];
			updatedDevices.forEach((device, index) => {
			  device.selected = false;
			});
			setActivatableDevices(updatedDevices);
		}
        props.finalize()
		//setActivating(false)
	}

	const selectDevice = (index) => {
        let updatedDevices = activatableDevices ? [...activatableDevices] : [];
        if (index !== -1) {
            var device = updatedDevices[index];
            if (device.selected !== true){
                device.selected = true;
            }else{
                device.selected = false;
            } 
            updatedDevices[index] = device;
            setActivatableDevices(updatedDevices);
          }
    }

    const finalizePurchase = () => {
		let obj = {
			type: period ? "month" : "year",
			plan: selectedPlan,
			//quantity: getCheckedDevices(),
			devices: getCheckedImeis(),
			//deviceTrial: [],
			//trialPeriod: 0
		}
		request.postSubscription("", "payments/dataplan/start", obj).then(async (response) => {
			if(response.data.successful){
				if(response.data.status === "succeeded"){
					setThinking(false)
					nextStep()
				}else{
					const paymentIntent = await stripe.confirmCardPayment(response.data.client_secret);
					if(paymentIntent.paymentIntent.status === "succeeded"){
						setThinking(false)
						nextStep()
					}else{
						setThinking(false)
						setStep(6)	
					}
				}
			}else{
				setThinking(false)
				setStep(6)
			}
		})
	}

	useEffect(() => {
		if(step === 4){
			setThinking(true)
			finalizePurchase()
		}
	}, [step])

	const getCheckedDevices = () => {
		let checkedDevices = activatableDevices.filter(device => device.selected);
		return checkedDevices.length
	}

	const getCheckedImeis = () => {
		let checkedDevices = activatableDevices.filter(device => device.selected).map(device => device.uniqueid);
		return checkedDevices
	}

	const calculatePrice = () => {
		let variant = period ? "monthly" : "yearly"
		let length = getCheckedDevices()

		let combined = period ? pricing[country]?.[selectedPlan]?.[variant] : (pricing[country]?.[selectedPlan]?.[variant] * 12 + (ssf ? 120 : 0))
		let result = combined * length
		return result
	}

	const calculateDifference = () => {
		let length = getCheckedDevices()
		let actual = (pricing[country]?.[selectedPlan]["yearly"] * 12) * length
		let expensive = (pricing[country]?.[selectedPlan]["monthly"] * 12) * length
		let result = expensive - actual
		return result	
	}

    useEffect(() => {
		if(allSelected){
			let updatedDevices = activatableDevices ? [...activatableDevices] : [];
            updatedDevices.map((device, index) => {
				device.selected = true
            })
			setActivatableDevices(updatedDevices)
		}else{
			let updatedDevices = activatableDevices ? [...activatableDevices] : [];
            updatedDevices.map((device, index) => {
				device.selected = false
            })
			setActivatableDevices(updatedDevices)
		}
    }, [allSelected])


	const openModal = (variant) => {
		setShowModal(!showModal)
		setType(variant)
	}

    const nextStep = (plan = selectedPlan) => {
		setSelectedPlan(plan)
		setStep(step + 1)
	}

	const segments = [
		{
			label: t("subscription.monthly"),
			value: true
		},
		{
			label: t("subscription.yearly"),
			value: false
		}
	]

	const resetStep = (stepValue) => {
		setStep(stepValue)
		if(stepValue === 1){
			setSsf(false)
			setSelectedPlan("")
			setPeriod(period)
			setAllSelected(false)
			if (!allSelected) {
				let updatedDevices = activatableDevices ? [...activatableDevices] : [];
				updatedDevices.forEach((device, index) => {
				  device.selected = false;
				});
				setActivatableDevices(updatedDevices);
			}
		}
	}

    const stepCounter = () => (
		<div className="d-flex flex-row align-items-center justify-content-center mt-3">
			<div className="d-flex flex-column align-items-center" onClick={() => resetStep(1)}>
				<div className={`activate-step-counter bold ${step === 1 ? "current": step > 1 ? "done" : "shadow-inner dark_grey"}`}><p>1</p></div>
				<p className={`bold mt-2 ${step === 1 ? "current": step > 1 ? "primary" : "dark_grey"}`}>{t("subscription.select_plan")}</p>
			</div>
			<div className="d-flex flex-column align-items-center mx-2">
				<div className={`activate-step-divider mb-4 bold ${step > 1 ? "done" : ""}`} />
			</div>
			<div className="d-flex flex-column align-items-center">
				<div className={`activate-step-counter bold ${step === 2 ? "current": step > 2 ? "done" : "shadow-inner dark_grey"}`}><p>2</p></div>
				<p className={`bold mt-2 ${step === 2 ? "current": step > 2 ? "primary" : "dark_grey"}`}>{t("subscription.details")}</p>
			</div>
			<div className="d-flex flex-column align-items-center mx-2">
				<div className={`activate-step-divider mb-4 bold ${step > 2 ? "done" : ""}`} />
			</div>
			<div className="d-flex flex-column align-items-center">
				<div className={`activate-step-counter bold ${step === 3 ? "current": step > 3 ? "done" : "shadow-inner dark_grey"}`}><p>3</p></div>
				<p className={`bold mt-2 ${step === 3 ? "current": step > 3 ? "primary" : "dark_grey"}`}>{t("subscription.checkout")}</p>
			</div>
		</div>
	)

	const stats = (variant) => {
		return (
			<>
				<div className="d-flex flex-row align-items-center my-2">
					{stoplight("green")}
					<p className="ms-2 bold">
						{variant === "basic"
							? t("subscription.120_sec_update")
							: variant === "boost"
							? t("subscription.15_sec_update")
							: t("subscription.10_sec_update")
						}
					</p>
				</div>
				<div className="d-flex flex-row align-items-center my-2">
					{variant === "basic" || variant === "boost"
						?
						<>
						{stoplight("yellow")}
						<p className="ms-2 bold">{t("subscription.eu_tracking")}<sup>1</sup></p>
						</>
						:
						<>
						{stoplight("green")}
						<p className="ms-2 bold">{t("subscription.world_tracking")}<sup>2</sup></p>
						</>
					}
				</div>
				<div className="d-flex flex-row align-items-center my-2">
					{variant === "basic"
						?
						<>
							{stoplight("yellow")}
							<p className="ms-2 bold">{t("subscription.history_1_week")}</p>
						</>
						: variant === "boost"
						?
						<>
						{stoplight("green")}
						<p className="ms-2 bold">{t("subscription.history_2_years")}</p>
						</>
						:
						<>
						{stoplight("green")}
						<p className="ms-2 bold">{t("subscription.history_5_years")}</p>
						</>
					}
				</div>
				<div className="d-flex flex-row align-items-center my-2">
					{variant === "basic"
						?
						<>
							{stoplight("yellow")}
							<p className="ms-2 bold">{t("subscription.geofences_1")}</p>
						</>
						: variant === "boost"
						?
						<>
						{stoplight("green")}
						<p className="ms-2 bold">{t("subscription.geofences_10")}</p>
						</>
						:
						<>
						{stoplight("green")}
						<p className="ms-2 bold">{t("subscription.geofences_unlimited")}</p>
						</>
					}
				</div>
				<div className="d-flex flex-row align-items-center my-2">
					{variant === "basic"
						?
						<>
						{stoplight("yellow")}
						<p className="ms-2 bold">{t("subscription.powersaving_always_on")}</p>
						</>
						: variant === "boost"
						?
						<>
						{stoplight("green")}
						<p className="ms-2 bold">{t("subscription.powersaving_on_off")}</p>
						</>
						:
						<>
						{stoplight("green")}
						<p className="ms-2 bold">{t("subscription.powersaving_on_off")}</p>
						</>
					}
				</div>
				<div className="d-flex flex-row align-items-center my-2">
					{variant === "basic"
						?
						<>
						{stoplight("red")}
						<p className="ms-2 bold">{t("subscription.angle_detection_off")}</p>
						</>
						: variant === "boost"
						?
						<>
						{stoplight("green")}
						<p className="ms-2 bold">{t("subscription.angle_detection_on")}</p>
						</>
						:
						<>
						{stoplight("green")}
						<p className="ms-2 bold">{t("subscription.angle_detection_on")}</p>
						</>
					}
				</div>
				<div className="d-flex flex-row align-items-center my-2">
					{variant === "basic"
						?
						<>
						{stoplight("red")}
						<p className="ms-2 bold">{t("subscription.export_data_off")}</p>
						</>
						: variant === "boost"
						?
						<>
						{stoplight("green")}
						<p className="ms-2 bold">{t("subscription.export_data_on")}</p>
						</>
						:
						<>
						{stoplight("green")}
						<p className="ms-2 bold">{t("subscription.export_data_on")}</p>
						</>
					}
				</div>
				<div className="d-flex flex-row align-items-center my-2">
					{variant === "basic"
						?
						<>
						{stoplight("red")}
						<p className="ms-2 bold">{t("subscription.share_account_off")}</p>
						</>
						: variant === "boost"
						?
						<>
						{stoplight("green")}
						<p className="ms-2 bold">{t("subscription.share_account_5")}</p>
						</>
						:
						<>
						{stoplight("green")}
						<p className="ms-2 bold">{t("subscription.share_account_unlimited")}</p>
						</>
					}
				</div>
				{variant === "basic" || variant === "boost"
					? <p className="my-4 dark_grey"><sup>1</sup> {t("subscription.boost_disclaimer")}</p>
					: <p className="my-4 dark_grey"><sup>2</sup> {t("subscription.boost_disclaimer_premium")}</p>
				}
			</>
		)
	}

	const stoplight = (val) => {
		let icon = ""
		val === "green"
		? icon = <img src={icons.stoplight_green} alt=""/>
		: val === "yellow"
		? icon = <img src={icons.stoplight_yellow} alt=""/>
		: icon = <img src={icons.stoplight_red} alt=""/>
		return icon
	}

	const renderStep1 = () => {
		if(MOBILE){
			return(
			<>
			<div className="d-flex justify-content-center my-3">
				<MonthYearSegment 
					segments={segments} 
					period={period} 
					callback={(val) => setPeriod(val)}
					segmentWidth={"w-75"}
					className=""
				/>
			</div>
			{/* BASIC CARD*/}
			<div className="card p-4 d-flex flex-column justify-content-between border-20 border-0 shadow-small">
				<div className="d-flex flex-row justify-content-between align-items-center">
					<h2 className='primary' onClick={() => openModal("basic")}>{t("subscription.basic")}<sup><img className="size_15 ms-1" src={icons.info_icon} alt=""></img></sup></h2>
					<div className="d-flex">
						<div className={`d-flex flex-column align-items-end me-3 ${period ? "primary" : "dark_grey"}`}>
							<div className="d-flex">
								<h2>{period ? pricing[country]?.basic.monthly : pricing[country]?.basic.yearly}</h2>
								{'\u00A0'}
								<h4 className="align-self-end mb-1">{pricing[country]?.currency}</h4>
							</div>
							<p>{t("subscription.monthly")}</p>
						</div>
						<div className={`d-flex flex-column align-items-end ${!period ? "primary" : "dark_grey"}`}>
							<div className="d-flex">
								<h2>{period ? (pricing[country]?.basic.monthly * 12)  : (pricing[country]?.basic.yearly * 12)}</h2>
								{'\u00A0'}
								<h4 className="align-self-end mb-1"> {pricing[country]?.currency}</h4>
							</div>
							<p>{t("subscription.yearly")}</p>
						</div>
					</div>
				</div>
				<div className="d-flex flex-row mt-3 align-items-center justify-content-between">
					<p className="dark_grey">{t("subscription.basic_subtitle")}</p>
					<div className="d-flex flex-column justify-content-end h-100">
						{/* {stats("basic")} */}
						<Button onClick={() => nextStep("basic")} btnClass="btn-primary gradient-button-primary w-100">{t("subscription.select_plan")}</Button>
					</div>
				</div>
			</div>
			{/* BOOST CARD */}
			<div className="card p-4 d-flex flex-column justify-content-between border-20 border-0 shadow-small my-3">
				<div className="d-flex flex-row justify-content-between position-relative align-items-center">
					<h2 className='primary' onClick={() => openModal("boost")}>{t("subscription.boost")}<sup className=" gradient bestvalue position-absolute"><p>{t("subscription.best_value")}</p></sup><sup><img className="size_15 ms-1" src={icons.info_icon} alt=""></img></sup></h2>
					<div className="d-flex">
						<div className={`d-flex flex-column align-items-end me-3 ${period ? "primary" : "dark_grey"}`}>
							<div className="d-flex">
								<h2>{period ? pricing[country]?.boost.monthly : pricing[country]?.boost.yearly}</h2>
								{'\u00A0'}
								<h4 className="align-self-end mb-1">{pricing[country]?.currency}</h4>
							</div>
							<p>{t("subscription.monthly")}</p>
						</div>
						<div className={`d-flex flex-column align-items-end ${!period ? "primary" : "dark_grey"}`}>
							<div className="d-flex">
								<h2>{period ? (pricing[country]?.boost.monthly * 12)  : (pricing[country]?.boost.yearly * 12)}</h2>
								{'\u00A0'}
								<h4 className="align-self-end mb-1">{pricing[country]?.currency}</h4>
							</div>
							<p>{t("subscription.yearly")}</p>
						</div>
					</div>
				</div>
				<div className="d-flex flex-row mt-3 align-items-center justify-content-between">
					<p className="dark_grey">{t("subscription.boost_subtitle")}</p>
					<div className="d-flex flex-column justify-content-end h-100">
							{/* {stats("boost")} */}
							<Button onClick={() => nextStep("boost")} btnClass="btn-primary w-100 gradient-button-primary">{t("subscription.select_plan")}</Button>
					</div>
				</div>
			</div>
			{/* PREMIUM CARD */}
			<div className="card p-4 d-flex flex-column justify-content-between border-20 border-0 shadow-small mb-3">
				<div className="d-flex flex-row justify-content-between align-items-center">
					<h2 className='primary' onClick={() => openModal("premium")}>{t("subscription.premium")}<sup><img className="size_15 ms-1" src={icons.info_icon} alt=""></img></sup></h2>
					<div className="d-flex">
						<div className={`d-flex flex-column align-items-end me-3 ${period ? "primary" : "dark_grey"}`}>
							<div className="d-flex">
								<h2>{period ? pricing[country]?.premium.monthly : pricing[country]?.premium.yearly}</h2> 
								{'\u00A0'}
								<h4 className="align-self-end mb-1">{pricing[country]?.currency}</h4>
							</div>
							<p>{t("subscription.monthly")}</p>
						</div>
						<div className={`d-flex flex-column align-items-end ${!period ? "primary" : "dark_grey"}`}>
							<div className="d-flex">
								<h2>{period ? (pricing[country]?.premium.monthly * 12)  : (pricing[country]?.premium.yearly * 12)}</h2>
								{'\u00A0'}
								<h4 className="align-self-end mb-1">{pricing[country]?.currency}</h4>
							</div>
							<p>{t("subscription.yearly")}</p>
						</div>
					</div>
				</div>
				<div className="d-flex flex-row mt-3 align-items-center justify-content-between">
					<p className="dark_grey">{t("subscription.premium_subtitle")}</p>
					<div className="d-flex flex-column justify-content-end h-100">
						{/* {stats("premium")} */}
						<Button onClick={() => nextStep("premium")} btnClass="btn-primary gradient-button-primary w-100">{t("subscription.select_plan")}</Button>
					</div>
				</div>
			</div>
			</>
			)
		}else{
			return(
				<div className="mt-4 d-flex flex-column align-items-center">
					<MonthYearSegment 
						segments={segments} 
						period={period} 
						callback={(val) => setPeriod(val)}
						segmentWidth={""}
					/>
					<div className="d-flex flex-row mt-4">
						{/* BASIC CARD */}
						<div className="card pt-5 pb-4 px-4 d-flex flex-column border-20 border-0 shadow-small dataplan-card-width">
							<h2 className="align-self-center primary">{t("subscription.basic")}</h2>
							<p className="align-self-center dark_grey mt-2">{t("subscription.basic_subtitle")}</p>
							<div className="d-flex justify-content-center my-4">
								<div className={`d-flex flex-column align-items-end me-4 ${period ? "primary" : "dark_grey"}`}>
									<div className="d-flex">
										<h1>{period ? pricing[country]?.basic.monthly : pricing[country]?.basic.yearly}</h1>
										{'\u00A0'}
										<h4 className="align-self-end mb-1">{pricing[country]?.currency}</h4>
									</div>
									<p>{t("subscription.monthly")}</p>
								</div>
								<div className={`d-flex flex-column align-items-end ${!period ? "primary" : "dark_grey"}`}>
									<div className="d-flex">
										<h1>{period ? (pricing[country]?.basic.monthly * 12)  : (pricing[country]?.basic.yearly * 12)}</h1>
										{'\u00A0'}
										<h4 className="align-self-end mb-1"> {pricing[country]?.currency}</h4>
									</div>
									<p>{t("subscription.yearly")}</p>
								</div>
							</div>
							<div className="d-flex flex-column justify-content-end h-100 primary">
								{stats("basic")}
								<Button onClick={() => nextStep("basic")} btnClass="btn-primary gradient-button-primary w-100 p-3 border-12">{t("subscription.select_plan")}</Button>
							</div>
						</div>
						
						{/* BOOST CARD */}
						<div className="card pt-5 pb-4 px-4 d-flex flex-column shadow-medium mx-4 border-20 border-0 dataplan-card-width">
							<div className="d-flex position-relative justify-content-center">
								<h2 className="align-self-center primary text-center">{t("subscription.boost")}<p className="bold body gradient position-absolute bestvalue">{t("subscription.best_value")}</p></h2>
							</div>
							<p className="align-self-center dark_grey mt-2">{t("subscription.boost_subtitle")}</p>
							<div className="d-flex justify-content-center my-4">
								<div className={`d-flex flex-column align-items-end me-4 h-100 ${period ? "primary" : "dark_grey"}`}>
									<div className="d-flex">
										<h1>{period ? pricing[country]?.boost.monthly : pricing[country]?.boost.yearly}</h1>
										{'\u00A0'}
										<h4 className="align-self-end mb-1">{pricing[country]?.currency}</h4>
									</div>
									<p>{t("subscription.monthly")}</p>
								</div>
								<div className={`d-flex flex-column align-items-end ${!period ? "primary" : "dark_grey"}`}>
									<div className="d-flex">
										<h1>{period ? (pricing[country]?.boost.monthly * 12)  : (pricing[country]?.boost.yearly * 12)}</h1>
										{'\u00A0'}
										<h4 className="align-self-end mb-1">{pricing[country]?.currency}</h4>
									</div>
									<p>{t("subscription.yearly")}</p>
								</div>
							</div>
							<div className="d-flex flex-column justify-content-end h-100 primary">
								{stats("boost")}
								<Button onClick={() => nextStep("boost")} btnClass="btn-primary w-100 gradient-button-primary p-3 border-12">{t("subscription.select_plan")}</Button>
							</div>
						</div>
						{/* PREMIUM CARD */}
						<div className="card pt-5 pb-4 px-4 d-flex flex-column border-20 border-0 shadow-small dataplan-card-width">
							<h2 className="align-self-center primary">{t("subscription.premium")}</h2>
							<p className="align-self-center dark_grey mt-2">{t("subscription.premium_subtitle")}</p>
							<div className="d-flex justify-content-center my-4">
								<div className={`d-flex flex-column align-items-end me-4 ${period ? "primary" : "dark_grey"}`}>
									<div className="d-flex">
										<h1>{period ? pricing[country]?.premium.monthly : pricing[country]?.premium.yearly}</h1> 
										{'\u00A0'}
										<h4 className="align-self-end mb-1">{pricing[country]?.currency}</h4>
									</div>
									<p>{t("subscription.monthly")}</p>
								</div>
								<div className={`d-flex flex-column align-items-end ${!period ? "primary" : "dark_grey"}`}>
									<div className="d-flex">
										<h1>{period ? (pricing[country]?.premium.monthly * 12)  : (pricing[country]?.premium.yearly * 12)}</h1>
										{'\u00A0'}
										<h4 className="align-self-end mb-1">{pricing[country]?.currency}</h4>
									</div>
									<p>{t("subscription.yearly")}</p>
								</div>
							</div>
							<div className="d-flex flex-column justify-content-start h-100 primary">
								{stats("premium")}
								<Button onClick={() => nextStep("premium")} btnClass="btn-primary gradient-button-primary w-100 p-3 border-12">{t("subscription.select_plan")}</Button>
							</div>
						</div>	
					</div>
				</div>
			)
		}
		
	}
	const renderStep2 = () => {

		const purchaseSummaryMobile = () => {
			return(
				<div className="position-absolute bottom-0 start-0 end-0 px-3 pb-3">
					<div className="card p-4 d-flex flex-column border-12 shadow-medium border-0">
						<div className="d-flex flex-row justify-content-between">
							<div className="d-flex flex-column">
								<p className="dark_grey">{t("subscription.total")}/{period ? t("subscription.month") : t("subscription.year")}</p>
								<div className="d-flex flex-column">
									<div className="d-flex">
										<h1>{calculatePrice()}</h1>
										{'\u00A0'}
										<h4 className="align-self-end mb-1">{pricing[country]?.currency}</h4>
									</div>
								</div>
							</div>
							<div className="d-flex flex-column justify-content-end">
								<Button onClick={() => nextStep()} btnClass="btn-primary gradient-button-primary">{t("subscription.proceed")}</Button>
							</div>
						</div>
					</div>
				</div>
			)
		}

		const purchaseSummaryDesktop = () => {
			return(
				<div className="ms-4">
					<div className="d-flex flex-row align-items-center px-3 opacity-0 mb-2">
						<h4>""</h4>
						<div className="centered_line ms-2" />
					</div>
					<div className="card p-4 d-flex flex-column border-12 shadow-medium border-0">
						<p className="align-self-center mb-3 bold dark_grey">{t("subscription.purchase_summary")}</p>
						<MonthYearSegment 
							segments={segments} 
							period={period} 
							callback={(val) => setPeriod(val)}
							segmentWidth={"w-100"}
						/>
						<p className="mt-4 dark_grey">{t("subscription.subscription")}</p>
						<h4 className="mt-2">{selectedPlan}</h4>
						<hr/>
						<p className="mt-2 dark_grey">{t("subscription.devices")}</p>
						<h4 className="mt-2">{getCheckedDevices() + " " + t("devices.device", {count : getCheckedDevices()})}</h4>
						<hr/>

						<p className="mt-2 dark_grey">{t("subscription.addons")}</p>
						<h4 className="mt-2">{ssf ? "SSF" : t("subscription.none")}</h4>
						<hr/>

						<p className="mt-2 dark_grey">{t("subscription.total")}/{period ? t("subscription.month") : t("subscription.year")}</p>
						<div className="d-flex flex-column mb-4">
							<div className="d-flex">
								<h1>{calculatePrice()}</h1>
								{'\u00A0'}
								<h4 className="align-self-end mb-1">{pricing[country]?.currency}</h4>
							</div>
							<p className={`${period || getCheckedDevices() < 1 ? "d-none" : "dark_grey"}`}>{t("subscription.total_savings")}:</p>
							<div className={`${period || getCheckedDevices() < 1 ? "d-none" : "green d-flex"}`}>
								<h2>{calculateDifference()}</h2>
								{'\u00A0'}
								<h4 className="align-self-end mb-1">{pricing[country]?.currency}</h4>
							</div>
						</div>

						<Button onClick={() => nextStep()} btnClass={`btn-primary gradient-button-primary w-100 mt-auto ${getCheckedDevices() < 1 ? "disabled": ""}`}>{t("subscription.proceed")}</Button>
					</div>
				</div>
			)
		}
		if(MOBILE){
			return (
				<>
					<div className="d-flex flex-column my-3">
						<div className="d-flex justify-content-center mb-3">
							<MonthYearSegment 
								segments={segments} 
								period={period} 
								callback={(val) => setPeriod(val)}
								segmentWidth={"w-75"}
								className=""
							/>
						</div>
						<div className="d-flex flex-row align-items-center px-3 mb-2">
							<h4 className="text-nowrap">{t("subscription.select_devices")}</h4>
							<div className="centered_line ms-3" />
						</div>
							<div className="border-12 subscription_device_list scrollable-y shadow-medium">
								<DeviceActivationList 
									text={t("devices.choose_all")}
									selected={activatableDevices.some(device => !device.selected) ? false : true}
									onClick={() => setAllSelected(!allSelected)}
								/>
								{activatableDevices.map((device, index) => (
									<DeviceActivationList
										key={index}
										text={device.name}
										subtitle={device.uniqueid}
										index={index}
										selected={device.selected ? true : false}
										onClick={() => selectDevice(index)}
									/>
								))}
							</div>
					</div>
					{activatableDevices.some(device => device.selected) &&
						purchaseSummaryMobile()
					}
				</>
			)
		}else{
			return(
				<>
					<div className="d-flex flex-row justify-content-center mt-4">
						<div className="d-flex flex-column w-75" style={{maxWidth: "500px"}}>
							<div className="d-flex flex-row align-items-center px-3 mb-2">
								<h4 className="text-nowrap">{t("subscription.select_devices")}</h4>
								<div className="centered_line ms-3" />
							</div>
							<div className="border-12 subscription_device_list scrollable-y shadow-medium">
								<DeviceActivationList 
									text={t("devices.choose_all")}
									selected={activatableDevices.some(device => !device.selected) ? false : true}
									onClick={() => setAllSelected(!allSelected)}
								/>
								{activatableDevices.map((device, index) => (
									<DeviceActivationList
										key={index}
										text={device.name}
										subtitle={device.uniqueid}
										index={index}
										selected={device.selected ? true : false}
										onClick={() => selectDevice(index)}
									/>
								))}
							</div>
							<div className="d-flex flex-row align-items-center mt-4 px-3 mb-2">
								<h4 className="text-nowrap">{t("subscription.select_addons")}</h4>
								<div className="centered_line ms-3" />
							</div>
							{!period 
								?
								<>
								<div className="card p-4 shadow-medium border-12 d-flex flex-column border-0 ">
									<div className="d-flex flex-row justify-content-between align-items-start w-100">
										<div className="d-flex align-items-center">
											<img className="me-2" src={icons.ssf} alt=""/>
											<h3>{t("subscription.ssf_support")}</h3>
										</div>
										<div className="d-flex justify-content-end ms-2">
											<h2>120</h2>
											{'\u00A0'}
											<h4 className="align-self-end mb-1">{pricing[country]?.currency}</h4>
											<div className="d-flex flex-column ps-2">
												<p className="text-wrap ">{t("subscription.per_device")}</p>
												<p className="text-wrap ">{t("subscription.yearly")}</p>
											{/* <p className="text-wrap ">{t("subscription.ssf_price_desc")}</p>
											<p className="text-wrap ">{t("subscription.ssf_price_desc")}</p> */}
											</div>
										</div>
									</div>
									<p className="dark_grey py-3">{t("subscription.ssf_subtitle")}</p>
									<Button onClick={() => setSsf(!ssf)} btnClass="btn-primary float-end align-self-end">{ssf ? t("subscription.remove") : t("subscription.add")}</Button>
								</div>
								</>
								: <h3 className="align-self-center dark_grey mt-3">{t("subscription.no_available")}</h3>
							}
						</div>
	
						<div className="w-50" style={{maxWidth: "350px"}}>
							{purchaseSummaryDesktop()}
						</div>
					</div>
				</>
			)
		}	
	}
	const renderStep3 = () => {

		const purchaseSummaryMobile = () => {
			return(
				<div className="position-absolute bottom-0 start-0 end-0 px-3 pb-3">
					<div className="card p-4 d-flex flex-column border-12 shadow-medium border-0">
						<div className="d-flex flex-row justify-content-between">
							<div className="d-flex flex-column">
								<p className="dark_grey">{t("subscription.total")}/{period ? t("subscription.month") : t("subscription.year")}</p>
								<div className="d-flex flex-column">
									<div className="d-flex">
										<h1>{calculatePrice()}</h1>
										{'\u00A0'}
										<h4 className="align-self-end mb-1">{pricing[country]?.currency}</h4>
									</div>
								</div>
							</div>
							<div className="d-flex flex-column justify-content-end">
								<Button onClick={() => finalizePurchase()} btnClass={`btn-primary ${paymentType !== "card" ? "disabled" : ""} gradient-button-primary w-100 mt-auto ${getCheckedDevices() < 1 ? "disabled": ""}`}>{t("subscription.proceed")}</Button>
							</div>
						</div>
					</div>
				</div>
			)
		}

		const purchaseSummaryDesktop = () => {
			return(
				<>
					<div className="d-flex flex-row align-items-center px-3 mb-2 opacity-0">
						<h4>""</h4>
						<div className="centered_line ms-2" />
					</div>
					<div className="ms-4">
						<div className="card p-4 d-flex flex-column border-12 shadow-medium border-0">
							<p className="align-self-center mb-3 bold dark_grey">{t("subscription.purchase_summary")}</p>

							<p className="mt-2 dark_grey">{t("subscription.subscription")}</p>
							<h4 className="mt-2">{selectedPlan}</h4>
							<hr/>
							<p className="dark_grey">{t("subscription.devices")}</p>
							<h4 className="mt-2">{getCheckedDevices() + " " + t("devices.device", {count : getCheckedDevices()})}</h4>
							<hr/>

							<p className="dark_grey">{t("subscription.addons")}</p>
							<h4 className="mt-2">{ssf ? "SSF" : t("subscription.none")}</h4>
							<hr/>

							<p className="dark_grey">{t("subscription.total")}/{period ? t("subscription.month") : t("subscription.year")}</p>
							<div className="d-flex flex-column mb-4">
								<div className="d-flex">
									<h1>{calculatePrice()}</h1>
									{'\u00A0'}
									<h4 className="align-self-end mb-1">{pricing[country]?.currency}</h4>
								</div>
								<p className={`${period || getCheckedDevices() < 1 ? "d-none" : "dark_grey"}`}>{t("subscription.total_savings")}:</p>
								<div className={`${period || getCheckedDevices() < 1 ? "d-none" : "green d-flex"}`}>
									<h2>{calculateDifference()}</h2>
									{'\u00A0'}
									<h4 className="align-self-end mb-1">{pricing[country]?.currency}</h4>
								</div>
							</div>

							<div className="w-100 mb-4">
								<input
									type="text"
									className="form-control input_box stripe-input p-2"
									placeholder={t("subscription.coupon_code")}
								/>
							</div>

							<Button onClick={() => nextStep()} btnClass={`btn-primary ${paymentType !== "card" ? "disabled" : ""} gradient-button-primary w-100 mt-auto ${getCheckedDevices() < 1 ? "disabled": ""}`}>{t("subscription.proceed")}</Button>
						</div>
					</div>
				</>
			)
		}
		if(MOBILE){
			return(
				<div className="d-flex flex-column my-3 mb-5">
					<div className="d-flex flex-row align-items-center px-3 mb-2">
						<h4 className="text-nowrap">{t("subscription.payment_method")}</h4>
						<div className="centered_line ms-3" />
					</div>
					<div className="card border-0 p-4 w-100 shadow-medium border-12">
						<div className="d-flex flex-row justify-content-between align-items-center">
							<h4>{t("subscription.card_payment")}</h4>
							<img onClick={() => setPaymentType(paymentType === "card" ? "" : "card")} src={paymentType === "card" ? icons.radio_checked : icons.radio_unchecked} alt=""/>
						</div>
						<Collapse in={paymentType === "card"}>
							<div className="d-flex flex-column mt-4">
								
									<p className="bold mb-1">{t("subscription.add_card")}</p>
									<Elements stripe={stripePromise}>
										<AddCard />
									</Elements>
									<div className="centered_line my-4" />

									<div className="d-flex flex-row align-items-center mb-2">
										<p className="bold">{t("subscription.payment_method")}</p>
									</div>
							
								{cards.length > 0
									?
									cards.map((card, index) => (
										<CardRow key={index} data={card}/>
									))
									: <>						
										<Button className="mt-3" btnClass="btn-primary" onClick={() => setShowCard(true)}>{t("subscription.add_card")}</Button>
									</>
								}	
							</div>
						</Collapse>
					</div>
					<div className="card border-0 p-4 w-100 mt-3 shadow-medium border-12 mb-5">
						<div className="d-flex flex-row justify-content-between align-items-center">
							<h4>{t("subscription.invoice")}</h4>
							<img onClick={() => setPaymentType(paymentType === "invoice" ? "" : "invoice")} src={paymentType === "invoice" ? icons.radio_checked : icons.radio_unchecked} alt=""/>
						</div>
						<Collapse in={paymentType === "invoice"}>
							Hejsan
						</Collapse>
					</div>
					{purchaseSummaryMobile()}
				</div>
				
			)
		}else{
			return(
				<div className="d-flex flex-row justify-content-center mt-4">
					<div className="d-flex flex-column w-75" style={{maxWidth: "500px"}}>
							<div className="d-flex flex-row align-items-center px-3 mb-2">
								<h4 className="text-nowrap">{t("subscription.payment_method")}</h4>
								<div className="centered_line ms-3" />
							</div>
							<div className="card border-0 p-4 w-100 shadow-medium border-12">
								<div className="d-flex flex-row justify-content-between align-items-center">
									<h4>{t("subscription.card_payment")}</h4>
									<img onClick={() => setPaymentType(paymentType === "card" ? "" : "card")} src={paymentType === "card" ? icons.radio_checked : icons.radio_unchecked} alt=""/>
								</div>
								<Collapse in={paymentType === "card"}>
									<div className="d-flex flex-column mt-4">
										
										<p className="bold mb-1">{t("subscription.add_card")}</p>
										<Elements stripe={stripePromise}>
											<AddCard />
										</Elements>
										<div className="centered_line my-4" />

										<div className="d-flex flex-row align-items-center mb-2">
											<p className="bold">{t("subscription.payment_method")}</p>
										</div>
									
										{cards.map((card, index) => (
											<CardRow key={index} data={card}/>
										))}
									</div>
								</Collapse>
							</div>
							<div className="card border-0 p-4 w-100 mt-4 shadow-medium border-12">
								<div className="d-flex flex-row justify-content-between align-items-center">
									<h4>{t("subscription.invoice")}</h4>
									<img onClick={() => setPaymentType(paymentType === "invoice" ? "" : "invoice")} src={paymentType === "invoice" ? icons.radio_checked : icons.radio_unchecked} alt=""/>
								</div>
								<Collapse in={paymentType === "invoice"}>
									Hejsan
								</Collapse>
							</div>
						</div>
	
						<div className="w-50" style={{maxWidth: "350px"}}>
							{purchaseSummaryDesktop()}
						</div>
				</div>
			)
		}
		
	}

	useEffect(() => {
		let intervalId;
		if(thinking){
			let dotCount = 0
			intervalId = setInterval(() => {
				dotCount = (dotCount % 3) + 1; // Loop through 1, 2, 3 dots
				setDots('.'.repeat(dotCount))
			}, 500)
		}

		return () => {
			clearInterval(intervalId); // Clean up interval
		  };
	}, [thinking])



	const renderStep4 = () => {
		return(
			<div className={`mt-4 d-flex flex-column ${MOBILE ? "w-100" : "w-50"} align-self-center justify-content-center`}>
				<div className="card p-4 shadow-small border-12 d-flex">
					<h3 className="align-self-center mt-5">{t("subscription.processing")+dots}</h3>
				</div>
			</div>
		)
	}
	
	const renderStep5 = () => {
		return(
			<div className={`mt-4 d-flex flex-column ${MOBILE ? "w-100" : "w-50"} align-self-center justify-content-center`}>
				<div className="card p-4 w-100 shadow-small border-12 d-flex flex-column align-items-center">
					<h3 className="mt-5">{t("subscription.purchase_successful")}</h3>
					<p>{t("subscription.purchase_successful_body")}</p>
					<hr className="w-100"/>
					<div className="d-flex flex-row justify-content-between flex-fill w-100">
						<div className="d-flex flex-column">
							<p>{t("subscription.dataplan")}</p>
							<p>{selectedPlan}</p>
						</div>
						<div className="d-flex flex-column">
							<p>{t("subscription.devices")}</p>
							<p>{getCheckedDevices() + " " + t("devices.device", {count : getCheckedDevices()})}</p>
						</div>
						<div className="d-flex flex-column">
							<p>{t("subscription.addons")}</p>
							<p>{ssf ? "SSF" : t("subscription.none")}</p>
						</div>
						<div className="d-flex flex-column">
							<p>{t("subscription.payment_method")}</p>
							{/* <label>Card **** **** **** {cards[0].card.last4} {capitalizeFirstLetter(cards[0].card.brand)}</label> */}
						</div>
					</div>
					<hr className="w-100"/>
					<div className="d-flex flex-column align-items-center">
						<p>{t("subscription.total")}/{period ? t("subscription.month") : t("subscription.year")}</p>
						<p>{calculatePrice()}</p>
						<Button  onClick={() => purchaseDone()} btnClass="btn-primary gradient-button-primary mt-4">{t("subscription.done")}</Button>
					</div>
				</div>
			</div>
		)
	}
	const renderError = () => (
		<div className={`mt-4 d-flex flex-column ${MOBILE ? "w-100" : "w-50"} align-self-center justify-content-center`}>
			<div className="card p-4 shadow-small border-12 d-flex">
				<h3 className="align-self-center mt-5">{t("subscription.something_went_wrong")}</h3>
				<Button  onClick={() => purchaseDone()} btnClass="btn-primary gradient-button-primary mt-4">{t("subscription.go_back")}</Button>
			</div>
		</div>
	)
    
    return(
        <>
            <Button className="align-self-start mt-3" btnClass="btn-left-section" icon={icons.back} notext={true} onClick={() => step > 1 ? setStep(step - 1) : props.finalize()}/>
            {stepCounter()}
            {	step === 1
                ? renderStep1()
                : step === 2
                ? renderStep2()
                :step === 3
                ? renderStep3()
                :step === 4
                ? renderStep4()
                : step === 5
                ? renderStep5()
                : step === 6
				? renderError()
				: null
            }
        <SubscriptionModal show={showModal} type={type} onClose={() => setShowModal(!showModal)} />
		<EditCardModal show={showCard} cards={cards} updateCards={() => props.updateCards()} onClose={() => setShowCard(!showCard)} />

        </>
    )
}

export default Activating;