import React, { useState, useEffect } from 'react';
import { INDEX_URL, GOOGLE_MAPS_KEY, icons, FLAVOR } from "../../helpers/constants";
import { fixDate, getBatteryIcon } from "../../helpers/helperFunctions";
import SegmentedControl from '../SegmentedControl/SegmentedControl';
import TextField from "@mui/material/TextField";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs'; // Import dayjs
import 'dayjs/locale/sv';
import { MapTogglesProvider, useMapToggles } from '../Map/ToggleContext'; // Adjust the path as necessary
import { useNavigate } from 'react-router-dom';
import * as request from "../../services/fetchService";
import { useTranslation } from "react-i18next";
import Button from '../Buttons/Button';
import { useContextData } from '../ContextData';


const SharedMessage = (props) => {
    const [message, setMessage] = useState()
    const { t } = useTranslation();
	const { deviceListCompact, setDeviceListCompact } = useContextData();

    useEffect(() => {
        setMessage(props.message)
    }, [props])

	const getDevices = async() => {
        request.get("devices/compactinfo").then((response) => {
            setDeviceListCompact(response.data.devices)
        });
	};

    const handleInvite = (accept, id) => {
        request.post("", "device/share/acceptdenyinvitation", {deviceid: [id], accept: accept}).then((response) => {
            if(response.data.successful){
                getDevices()
                props.refreshList()
            }else{
                alert("error")
            }
        })
    }

    if(message){
        return(
            <div className="p-3 d-flex flex-column justify-content-center h-100">
                <img className="w-100 mx-auto" src={message.image} alt="" />
                <p>{t("inapp.share_device_body", {from: message.sharedfrom, device: message.devicename})}</p>
                <div className="d-flex justify-content-between mt-3">
                    <Button btnClass="btn-primary w-100 me-2" onClick={() => handleInvite(true, message.deviceid)}>{t("inapp.accept")}</Button>
                    <Button btnClass="btn-danger w-100 ms-2" onClick={() => handleInvite(false, message.deviceid)}>{t("inapp.decline")}</Button>
                </div>
            </div>
        )
    }

}

export default SharedMessage;