import React, { useState, useEffect } from 'react';
import { INDEX_URL, CUST, icons } from "../../helpers/constants";
import { useTranslation } from "react-i18next";
import * as request from "../../services/fetchService";
import { fixDate, translateLanguages } from '../../helpers/helperFunctions';
import Button from '../Buttons/Button';
import BillingModal from '../Modals/BillingModal';
import toast from 'react-hot-toast';

const Billing = (props) => {
	const { t } = useTranslation();
    const [billingInfo, setBillingInfo] = useState()
    const [showModal, setShowModal] = useState(false)
    const [type, setType] = useState()

    useEffect(() => {
        getBillingInfo()
    }, [])

    const getBillingInfo = () => {
        request.get("billing/settings").then((response) => {
            if(!response.data.error){
                setBillingInfo(response.data)
            }else{
                toast.error(t("toast.error"))
            }

        })
    }

    const openModal = (type) => {
        setType(type)
        setShowModal(!showModal)
    }

    const saveData = (data) => {
        request.post("", "billing/settingsupdate", data).then((response) => {
            if(response.data){
                setShowModal(false)
                getBillingInfo()
                toast.success(t("toast.saved"))
            }else{
                toast.error(t("toast.error"))
            }

        })
    }
    const items = ["vatnr", "receipt_lang", "email"]

    return(
        <>
        <p className="thin mb-1">{t("settings.billing")}</p>
        <div className="d-flex flex-column shadow-small settings-radius primary">
            {items.map((item, index) => (
                <div 
                key={index} 
                className={`p-3 d-flex justify-content-between bg-white ${index < items.length-1 ? "border-settings" : ""}`}>
                    {
                        item === "vatnr"
                        ? <h4 className="regular">{t("settings.vatnr")}</h4>
                        : item === "receipt_lang"
                        ? <h4 className="regular">{t("settings.receipt_language")}</h4>
                        : <h4 className="regular">{t("settings.email")}</h4>
                    }
                    <div className="cursor-pointer d-flex align-items-center" onClick={() => openModal(item)}>
                        {
                            item === "vatnr"
                            ? <p className="bold">{billingInfo && billingInfo.vatnumber}</p>
                            : item === "receipt_lang"
                            ? <p className="bold">{billingInfo && translateLanguages(t, billingInfo?.receiptlanguage?.split("-")[0])}</p>
                            : <p className="bold">{billingInfo && billingInfo.email}</p>
                        }
                        <img className="ms-3 size_15" src={icons.dots_vertical} alt={""} />
                    </div>
                </div>
            ))}
        </div>
        <div className="d-flex flex-column shadow-small primary settings-radius mt-5">
            <div className={`p-3 d-flex justify-content-between align-items-center bg-white`}>
                <h4 className="regular">{t("settings.send_to_email")}</h4>
                <div className="form-check form-switch">
                    <input className="form-check-input" onClick={() => saveData({autosendtomail: !billingInfo.autosendtomail})} type="checkbox" role="switch" id="flexSwitchCheckDefault" defaultChecked={billingInfo && billingInfo.autosendtomail} />
                </div>
            </div>
        </div>
        <BillingModal show={showModal} type={type} data={billingInfo} onSave={(data) => saveData(data)} onClose={() => setShowModal(!showModal)}/>
        </>
    )
}

export default Billing;