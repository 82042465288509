import React, { useState, useEffect, useRef } from 'react';

import { Dialog, Card } from '@mui/material';
import { images, icons } from "../../helpers/constants";
import { useTranslation } from "react-i18next";
import Button from '../Buttons/Button';
import { useIsMobile } from '../../helpers/customHooks';
import SegmentedControl from '../SegmentedControl/SegmentedControl';


const AccountModal = (props) => {
    const MOBILE = useIsMobile();
    const { t } = useTranslation();
    const inputFile = useRef(null);

    const [show, setShow] = useState(props.show)
    const [errorText, setErrorText] = useState("")
    const [username, setUsername] = useState("")
    const [age, setAge] = useState("")
    const [weight, setWeight] = useState("")
    const [currentImage, setCurrentImage] = useState()
    const [email, setEmail] = useState("")
    const [confirmEmail, setConfirmEmail] = useState("")
    const [currPass, setCurrPass] = useState("")
    const [newPassword, setNewPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [genderValue, setGenderValue] = useState()
    const [imgFile, setImgFile] = useState()

    useEffect(() => {
        setShow(props.show)

    },[props.show])

    useEffect(() => {
        if(props.type){
            setUsername(props.data.username)
            setAge(props.data.birthday && new Date(props.data.birthday).toISOString().split('T')[0])
            setGenderValue(props.data.gender && props.data.gender)
            setWeight(props.data.weight && props.data.weight)
            setCurrentImage(props.data.userimage && props.data.userimage)
        }
    }, [props])

    
    const handleClose = () => {
        props.onClose()
      //  setShow(false)
    }

    const renderGenderSegments = () => {
        const segments = [
            {
                label: t("settings.male"),
                value: "male"
            },
            {
                label: t("settings.female"),
                value: "female"
            },
            {
                label: t("settings.other"),
                value: "other"
            }
        ]
        return (
            <SegmentedControl
                name="psm"
                callback={(val) => handleChangeGender(val)}
                value={genderValue}
                segments={segments}
                segmentWidth="w-100"
            />
        )
    }

    const handleChangeGender = (value) => {
        setGenderValue(value)
    }

    const uploadHandler = () => {
        inputFile.current.click();
    }

    const handleUploadedImg = (imgfile) => {
        if (imgfile) {
            // Create a URL for the file
            const imageUrl = URL.createObjectURL(imgfile);
            // Update your component's state to reflect the new image
            setCurrentImage(imageUrl);
            setImgFile(imgfile)
        }
    }


    const handleChange = (type, e) => {
        switch(type){
            case "username":
                setUsername(e.target.value)
            break;
            case "email":
                setEmail(e.target.value)
            break;
            case "confirmEmail":
                setConfirmEmail(e.target.value)
            break;
            case "currPass":
                setCurrPass(e.target.value)
            break;
            case "newPassword":
                setNewPassword(e.target.value)
            break;
            case "confirmPassword":
                setConfirmPassword(e.target.value)
            break;
            case "age":
                setAge(e.target.value)
            break;
            case "weight":
                setWeight(e.target.value)
            break;
            default: return
        }
        setErrorText("")
    }

    const deleteImg = () => {
        let data = { imageurl: ""}
        props.onSave(data)
    }

    const handleSave = (type) => {
        let data = {}
        switch(type){
            case "img": 
                data = imgFile
                break;
            case "username":
                data = { username: username }
                break;
            case "email":
                data = { newemail: email, newemailconfirm: confirmEmail }
                break;
            case "password":
                data = { oldpassword: currPass, newpassword: newPassword, newpasswordconfirm: confirmPassword }
                break;
            case "gender":
                data = { gender: genderValue }
                break;
            case "age":
                data = { birthday: age }
                break;
            case "weight":
                data = { weight: parseInt(weight) }
                break;
            default: data = {}
        }
        props.onSave(data)
    }

    const imgContent = () => {
        return (
            <>
            <div className="d-flex flex-row p-3 justify-content-between border-settings align-items-center">
                <div className='d-flex align-items-center'>
                    <img src={icons.user_settings} alt=""/>
                    <h3 className="ms-2">{t("settings.change_image")}</h3>
                </div>
                <img className="cursor-pointer" src={icons.close_gray} onClick={() => props.onClose()} alt="..." /> 
            </div>
            <div className="p-3">
                <p className="thin dark-grey text-center pb-3">{t("settings.change_image_sub")}</p>
                <div className="d-flex flex-column justify-content-center align-items-center">
                    <img src={currentImage} alt ="" className="w-50" />
                    <p className="thin">{t("settings.preview")}</p>
                    <Button className="mt-3" icon={icons.plus} onClick={uploadHandler}>{t("settings.upload")}</Button>
                    <input
                        type="file"
                        id="file"
                        className="cursor-pointer invisible"
                        onChange={(e) => handleUploadedImg(e.target.files[0])}
                        ref={inputFile}
                        style={{ display: "none" }}
                    />
                </div>
            </div>
            <div className="d-flex flex-row p-3 justify-content-between border-settings-top align-items-center">
                <Button onClick={() => deleteImg()} btnClass="btn-danger justify-self-start disabled">{t("settings.delete")}</Button>
                <div>
                    <Button onClick={() => props.onClose()} btnClass="btn-secondary me-2">{t("settings.cancel")}</Button>
                    <Button onClick={() => handleSave(props.type)} btnClass="btn-primary">{t("settings.save")}</Button>
                </div>

            </div>
            </>
        )
    }

    const userNameContent = () => {
        return (
            <>
            <div className="d-flex flex-row p-3 justify-content-between border-settings align-items-center">
                <div className='d-flex align-items-center'>
                    <img src={icons.user_settings} alt=""/>
                    <h3 className="ms-2">{t("settings.change_username")}</h3>
                </div>
                <img className="cursor-pointer" src={icons.close_gray} onClick={() => props.onClose()} alt="..." /> 
            </div>
            <div className="p-3">
                <p className="thin dark-grey text-center pb-3">{t("settings.change_username_sub")}</p>
                <form>
                    <div className="form-group d-flex align-items-center">
                        <input
                            type="text"
                            className="form-control bg-light-grey p-2"
                            id="name"
                            defaultValue={username}
                            onChange={(e) => {
                                handleChange("username", e);
                            }}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                e.preventDefault();// Prevent the default Enter key behavior
                                handleSave(props.type)
                                }
                            }}
                        />
                        <div className="invalid-feedback ms-1 bottom">
                            {errorText === "" ? "" : errorText}
                        </div>
                    </div>
                </form>
            </div>
            <div className="d-flex flex-row p-3 justify-content-end border-settings-top align-items-center">
               <Button onClick={() => props.onClose()} btnClass="btn-secondary me-2">{t("settings.cancel")}</Button>
               <Button onClick={() => handleSave(props.type)} btnClass="btn-primary">{t("settings.save")}</Button>
            </div>
            </>
        )
    }

    const emailContent = () => {
        return (
            <>
            <div className="d-flex flex-row p-3 justify-content-between border-settings align-items-center">
                <div className='d-flex align-items-center'>
                    <img src={icons.user_settings} alt=""/>
                    <h3 className="ms-2">{t("settings.change_email")}</h3>
                </div>
                <img className="cursor-pointer" src={icons.close_gray} onClick={() => props.onClose()} alt="..." /> 
            </div>
            <div className="p-3 pb-0">
                <p className="thin dark-grey text-center pb-3">{t("settings.change_email_sub")}</p>

                <p className="thin">{t("settings.new_email")}</p>
                <form>
                    <div className="form-group d-flex align-items-center">
                        <input
                            type="text"
                            className="form-control bg-light-grey p-2"
                            id="name"	
                            defaultValue={email}
                            onChange={(e) => {
                                handleChange("email", e);
                            }}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                e.preventDefault();// Prevent the default Enter key behavior
                                document.getElementById("confirmemail").focus()
                                }
                            }}
                        />
                        <div className="invalid-feedback ms-1 bottom">
                            {errorText === "" ? "" : errorText}
                        </div>
                    </div>
                </form>
            </div>
            <div className="p-3">
                <p className="thin">{t("settings.confirm_new_email")}</p>
                <form>
                    <div className="form-group d-flex align-items-center">
                        <input
                            type="text"
                            className="form-control bg-light-grey p-2"
                            id="confirmemail"	
                            defaultValue={confirmEmail}
                            onChange={(e) => {
                                handleChange("confirmEmail", e);
                            }}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                e.preventDefault();// Prevent the default Enter key behavior
                                handleSave(props.type)
                                }
                            }}
                        />
                        <div className="invalid-feedback ms-1 bottom">
                            {errorText === "" ? "" : errorText}
                        </div>
                    </div>
                </form>
            </div>
            <div className="d-flex flex-row p-3 justify-content-end border-settings-top align-items-center">
               <Button onClick={() => props.onClose()}btnClass="btn-secondary me-2">{t("settings.cancel")}</Button>
               <Button onClick={() => handleSave(props.type)} btnClass="btn-primary" >{t("settings.change_email")}</Button>
            </div>
            </>
        )
    }
    const passwordContent = () => {
        return (
            <>
            <div className="d-flex flex-row p-3 justify-content-between border-settings align-items-center">
                <div className='d-flex align-items-center'>
                    <img src={icons.user_settings} alt=""/>
                    <h3 className="ms-2">{t("settings.change_password")}</h3>
                </div>
                <img className="cursor-pointer" src={icons.close_gray} onClick={() => props.onClose()} alt="..." /> 
            </div>
            <div className="p-3 pb-0">
                <p className="thin dark-grey text-center pb-3">{t("settings.change_password_sub")}</p>
                <p className="thin">{t("settings.current_password")}</p>
                <form>
                    <div className="form-group d-flex align-items-center">
                        <input
                            type="text"
                            className="form-control bg-light-grey p-2"
                            id="pass"	
                            defaultValue={currPass}
                            onChange={(e) => {
                                handleChange("currPass", e);
                            }}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                e.preventDefault();// Prevent the default Enter key behavior
                                document.getElementById("newPass").focus()
                                }
                            }}
                        />
                        <div className="invalid-feedback ms-1 bottom">
                            {errorText === "" ? "" : errorText}
                        </div>
                    </div>
                </form>
            </div>
            <div className="p-3">
                <p className="thin">{t("settings.new_password")}</p>
                <form>
                    <div className="form-group d-flex align-items-center">
                        <input
                            type="text"
                            className="form-control bg-light-grey p-2"
                            id="newPass"	
                            defaultValue={newPassword}
                            onChange={(e) => {
                                handleChange("newPassword", e);
                            }}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                e.preventDefault();// Prevent the default Enter key behavior
                                document.getElementById("confirmPass").focus()
                                }
                            }}
                        />
                        <div className="invalid-feedback ms-1 bottom">
                            {errorText === "" ? "" : errorText}
                        </div>
                    </div>
                </form>
            </div>
            <div className="p-3 pt-0">
                <p className="thin">{t("settings.confirm_password")}</p>
                <form>
                    <div className="form-group d-flex align-items-center">
                        <input
                            type="text"
                            className="form-control bg-light-grey p-2"
                            id="confirmPass"	
                            defaultValue={confirmPassword}
                            onChange={(e) => {
                                handleChange("confirmPassword", e);
                            }}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                e.preventDefault();// Prevent the default Enter key behavior
                                handleSave(props.type)
                                }
                            }}
                        />
                        <div className="invalid-feedback ms-1 bottom">
                            {errorText === "" ? "" : errorText}
                        </div>
                    </div>
                </form>
            </div>
            <div className="d-flex flex-row p-3 justify-content-end border-settings-top align-items-center">
               <Button onClick={() => props.onClose()} btnClass="btn-secondary me-2">{t("settings.cancel")}</Button>
               <Button onClick={() => handleSave(props.type)} btnClass="btn-primary">{t("settings.change_password")}</Button>
            </div>
            </>
        )
    }

    const genderContent = () => {
        return (
            <>
            <div className="d-flex flex-row p-3 justify-content-between border-settings align-items-center">
                <div className='d-flex align-items-center'>
                    <img src={icons.user_settings} alt=""/>
                    <h3 className="ms-2">{t("settings.change_gender")}</h3>
                </div>
                <img className="cursor-pointer" src={icons.close_gray} onClick={() => props.onClose()} alt="..." /> 
            </div>
            <div className="p-3">
                <p className="thin dark-grey text-center pb-3">{t("settings.change_gender_sub")}</p>
                {renderGenderSegments()}
            </div>
            <div className="d-flex flex-row p-3 justify-content-end border-settings-top align-items-center">
               <Button onClick={() => props.onClose()} btnClass="btn-secondary me-2">{t("settings.cancel")}</Button>
               <Button onClick={() => handleSave(props.type)} btnClass="btn-primary">{t("settings.save")}</Button>
            </div>
            </>
        )
    }


    const ageContent = () => {
        return (
            <>
            <div className="d-flex flex-row p-3 justify-content-between border-settings align-items-center">
                <div className='d-flex align-items-center'>
                    <img src={icons.user_settings} alt=""/>
                    <h3 className="ms-2">{t("settings.change_age")}</h3>
                </div>
                <img className="cursor-pointer" src={icons.close_gray} onClick={() => props.onClose()} alt="..." /> 
            </div>
            <div className="p-3">
                <p className="thin dark-grey text-center pb-3">{t("settings.change_age_sub")}</p>
                <form>
                    <div className="form-group d-flex align-items-center">
                        <input
                            type="date"
                            className="form-control bg-light-grey p-2"
                            id="name"
                            defaultValue={age}
                            onChange={(e) => {
                                handleChange("age", e);
                            }}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                e.preventDefault();// Prevent the default Enter key behavior
                                handleSave(props.type)
                                }
                            }}
                        />
                        <div className="invalid-feedback ms-1 bottom">
                            {errorText === "" ? "" : errorText}
                        </div>
                    </div>
                </form>
            </div>
            <div className="d-flex flex-row p-3 justify-content-end border-settings-top align-items-center">
               <Button onClick={() => props.onClose()} btnClass="btn-secondary me-2">{t("settings.cancel")}</Button>
               <Button onClick={() => handleSave(props.type)} btnClass="btn-primary">{t("settings.save")}</Button>
            </div>
            </>
        )
    }

    const weightContent = () => {
        return (
            <>
            <div className="d-flex flex-row p-3 justify-content-between border-settings align-items-center">
                <div className='d-flex align-items-center'>
                    <img src={icons.user_settings} alt=""/>
                    <h3 className="ms-2">{t("settings.change_weight")}</h3>
                </div>
                <img className="cursor-pointer" src={icons.close_gray} onClick={() => props.onClose()} alt="..." /> 
            </div>
            <div className="p-3">
                <p className="thin dark-grey text-center pb-3">{t("settings.change_weight_sub")}</p>
                <form>
                    <div className="form-group d-flex align-items-center">
                        <input
                            type="text"
                            className="form-control bg-light-grey p-2"
                            id="name"
                            defaultValue={weight}
                            onChange={(e) => {
                                handleChange("weight", e);
                            }}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                e.preventDefault();// Prevent the default Enter key behavior
                                handleSave(props.type)
                                }
                            }}
                        />
                        <div className="invalid-feedback ms-1 bottom">
                            {errorText === "" ? "" : errorText}
                        </div>
                    </div>
                </form>
            </div>
            <div className="d-flex flex-row p-3 justify-content-end border-settings-top align-items-center">
               <Button onClick={() => props.onClose()} btnClass="btn-secondary me-2">{t("settings.cancel")}</Button>
               <Button onClick={() => handleSave(props.type)} btnClass="btn-primary">{t("settings.save")}</Button>
            </div>
            </>
        )
    }


    const deleteContent = () => {
        return (
            <>
            <div className="d-flex flex-row p-3 justify-content-between border-settings align-items-center">
                <div className='d-flex align-items-center'>
                    <img src={icons.trashcan} alt=""/>
                    <h3 className="ms-2">{t("settings.delete_account")}</h3>
                </div>
                <img className="cursor-pointer" src={icons.close_gray} onClick={() => props.onClose()} alt="..." /> 
            </div>
            <div className="p-3">
                <p>{t("settings.delete_account_body")}</p>
            </div>
            <div className="d-flex flex-row p-3 justify-content-end border-settings-top align-items-center">
               <Button onClick={() => props.onClose()}btnClass="btn-secondary me-2">{t("settings.cancel")}</Button>
               <Button onClick={() => props.deleteAccount()} btnClass="btn-danger">{t("settings.delete")}</Button>
            </div>
            </>
        )
    }

    const typeRender = () => {
        switch(props.type){
            case "img": return imgContent()
            case "username": return userNameContent()
            case "email": return emailContent()
            case "password": return passwordContent()
            case "gender": return genderContent()
            case "age": return ageContent()
            case "weight": return weightContent()
            case "delete": return deleteContent()
            default: return null
        }
    }
    
    return(
        <>
        <Dialog fullWidth={MOBILE ? true : false} open={show} onClose={handleClose}>
            <Card className="settings-dialog d-flex flex-column">
                <div className="w-100 h-100">
                    {typeRender()}
                </div>
            </Card>
        </Dialog>
        </>
    )
}

export default AccountModal;