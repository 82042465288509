import { Accordion, AccordionSummary, Collapse } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { INDEX_URL, GOOGLE_MAPS_KEY, icons, FLAVOR } from "../../helpers/constants";
import { expandMap, externalPowerText, fixDate } from "../../helpers/helperFunctions";
import SegmentedControl from '../SegmentedControl/SegmentedControl';
import TextField from "@mui/material/TextField";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs'; // Import dayjs
import 'dayjs/locale/sv';
import { MapTogglesProvider, useMapToggles } from '../Map/ToggleContext'; // Adjust the path as necessary
import { useNavigate } from 'react-router-dom';
import * as request from "../../services/fetchService";
import { useTranslation } from "react-i18next";
import DeviceInfoCard from './DeviceInfoCard';
import ZoneModal from '../Modals/ZoneModal';
import toast from 'react-hot-toast';

const DeviceRowMobile = (props) => {
    const { t } = useTranslation();
    const [visibleDetail, setVisibleDetail] = useState(null)
    const [dataplanDate, setDataplanDate] = useState(new Date(props.device.dataplanexpiringdate))
    const [powerSaveValue, setPowerSaveValue] = useState(props.device.modelspecificinfo?.powersavingmode?.powersavingmodeinfo)
    const [powerSwitchValue, setPowerSwitchValue] = useState(props.device.modelspecificinfo?.powerswitch.powerswitchinfo)
    const [startDate, setStartDate] = useState(dayjs().subtract(1, 'day'))
    const [endDate, setEndDate] = useState(dayjs())
    const [toggleActive, setToggleActive] = useState(false)
    const { setActiveDevice } = useMapToggles();
    const navigate = useNavigate();
    const [imgLoaded, setImgLoaded] = useState(false)
    const [showZoneModal, setShowZoneModal] = useState(false)

    useEffect(() => {
        let mode = props.device.modelspecificinfo?.powersavingmode?.powersavingmodeinfo;
        setPowerSaveValue(mode);
    },[])
    
    const renderPowerSwitchSegment = (value) => {
        const segments = 
        props.device.modelspecificinfo?.relayswitch?.pending
        ?
        [{
            label: `${t("devices.power_pending")} ${props.device.modelspecificinfo?.relayswitch?.pendinginfo ? "On" : "Off"}`,
            value: !props.device.modelspecificinfo?.relayswitch?.pendinginfo
        }]
        :
        [
            {
                label: t("devices.on"),
                value: true
            },
            {
                label: t("devices.off"),
                value: false
            }
        ]
        return (
            <SegmentedControl
                name="psm"
                callback={(val) => handleChangePowerSwitchValue(val)}
                value={value}
                segments={segments}
                segmentWidth="w-100"
                pending={props.device.modelspecificinfo?.relayswitch?.pending}
            />
        )
    }

    const handleChangePowerSwitchValue = (value) => {
        let data = {
            deviceid: props.device.id,
            relayswitch: value
        }
        request.post("", "device/update-new", data).then((response) => {
            if(response.data.successful){
                props.refresh()
                toast.success(t("toast.success"))
            }else{
                toast.error(t("toast.error"))
            }
      
        }) 
    }

    const renderAlarmSwitch = (status) => {
        const segments = 
        props.device.modelspecificinfo?.alarmcenter?.triggered
        ?
        [{
            label: t("devices.triggered"),
            value: false
        }]
        :
        [
            {
                label: t("devices.on"),
                value: true
            },
            {
                label: t("devices.off"),
                value: false
            }
        ]
        return(
            <SegmentedControl
            name="psm"
            callback={(val) => handleAlarmValue(val)}
            value={status}
            segments={segments}
            triggered={props.device.modelspecificinfo?.alarmcenter?.triggered}
        /> 
        )
    }

    const handleAlarmValue = (value) => {
        if(props.device.latitude == null && props.device.longitude == null){
            toast.error(t("toast.no_pos_safety_zone"))
            return
        }
        if(value && !props.device.modelspecificinfo?.alarmcenter?.triggered){
            setShowZoneModal(true)
        }else if(!value && props.device.modelspecificinfo?.alarmcenter?.triggered){
            setShowZoneModal(true)
        }else{
            toggleAlarm(false)
        }
    }

    const toggleAlarm = (value) => {
        let data = {
            deviceid: props.device.id,
            alarmcenter_arm: value
        }
        request.post("", "device/update-new", data).then((response) => {
            if(response.data.successful){
                props.refresh()
                setShowZoneModal(false)
                toast.success(t("toast.success"))
            }else{
                toast.error(t("toast.error"))
            }
      
        })  
    }


    const renderPowersavingSegment = () => {
        const segments = [
            {
                label: t("devices.on"),
                value: true
            },
            {
                label: t("devices.off"),
                value: false
            }
        ]
        return (
            <SegmentedControl
                name="psm"
                callback={(val) => handleChangePowerSaveValue(val)}
                powerSaveValue={powerSaveValue}
                segments={segments}
            />
        )
    }

    const handleChangePowerSaveValue = (value) => {
        setPowerSaveValue(value)
    }

    const renderLocationHistory = () => {
        return (
            <div className="d-flex justify-content-start m-0 p-0">
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='sv'>
                    <DateTimePicker
                    ampm={false}
                    value={startDate}
                    onChange={(newValue) => setStartDate(newValue)}
                    maxDate={endDate}
                    className="date_label me-1"
                    />
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='sv'>
                    <DateTimePicker
                    ampm={false}
                    value={endDate}
                    onChange={(newValue) => setEndDate(newValue)}
                    minDate={startDate}
                    className="date_label ms-1"
                    />
                </LocalizationProvider>
                <img src={icons.next_arrow_btn} onClick={() => getLocationHistory(props.device.id)} className="cursor-pointer ms-3" alt=""/>
            </div>
        )
    }

    const getLocationHistory = (id) => {
        let obj = {
			deviceid: id,
			starttime: startDate.toDate(),
			stoptime: endDate.toDate()
		}
        request.post("", "history/list", obj).then((response) => {
			if(!response.data.errorCode){
                navigate("/history", {state: {history: response.data}})
            }
		});
    }

    const showOnMap = (event, device) => {
        event.stopPropagation();
        if(device.latitude && device.longitude){
            setActiveDevice(device.id)
            props.focusOnMap()
            // expandMap()
        }
    }
    
    const renderBikeDetail = () => {
        return (
            null
        )
    }

    const renderAlcoDetail = () => {
        return (
            null
        )
    }

    const renderDeviceDetail = () => {

        let powerSupply = {
            valid: props.device.modelspecificinfo?.externalpowersupply?.externalpowervalid,
            value: props.device.modelspecificinfo?.externalpowersupply?.externalpowerinfo
        }

        let externalBattery = {
            valid: props.device.visibleswitch.devicelist.battery_external,
            value: props.device.modelspecificinfo.externalbattery.externalbatteryinfo
        }
        let internalBattery = {
            valid: props.device.visibleswitch.devicelist.battery_internal,
            value: props.device.modelspecificinfo.internalbattery.internalbatteryinfo ? props.device.modelspecificinfo.internalbattery.internalbatteryinfo : 0  
        }
        let distance = {
            valid: props.device.visibleswitch.devicelist.distancetoday,
            value: "HEJ"
        }
        let temperature = {
            valid: props.device.visibleswitch.devicelist.temperature,
            value: (props.device.modelspecificinfo.temp_hum.currentdata.temperature/100).toFixed(1)
        }
        let humidity = {
            valid: props.device.visibleswitch.devicelist.humidity,
            value: (props.device.modelspecificinfo.temp_hum.currentdata.humidity/100).toFixed(1)
        }
        let altitude = {
            valid: props.device.visibleswitch.devicelist.altitude,
            value: props.device.modelspecificinfo?.positioninfo?.altitude ? `${props.device.modelspecificinfo?.positioninfo?.altitude}m ${t("devices.asl")}` : "N/A"
        }
        let ignition = {
            valid: props.device.visibleswitch.devicelist.ignition,
            value: props.device.modelspecificinfo.ignition.ignitioninfo
        }
        let dataplan = {
            valid: props.device.visibleswitch.devicelist.dataplan,
            value: new Date(props.device.dataplanexpiringdate) > new Date()
        }
        let relay = {
            // valid: props.device.visibleswitch.devicelist.relay_switch,
            valid: props.device.modelspecificinfo?.relayswitch?.valid,
            value: props.device.modelspecificinfo?.relayswitch?.currentinfo,
            pending: {
                status: props.device.modelspecificinfo?.relayswitch?.pending,
                value: props.device.modelspecificinfo?.relayswitch?.pendinginfo
            }
        }
        let alarmCentral = {
            valid:  props.device.visibleswitch.devicelist.alarmcentral_switch,
            value: props.device.modelspecificinfo.alarmcenter.armed
        }
        let location = {
            valid:  props.device.visibleswitch.devicelist.locationhistory,
        }

    let visibleCards = [
        {
            //External power
            name: "external_battery",
            visible: externalBattery.valid,
            icon: icons.battery_half,
            label: t("devices.external_power"),
            value: `${externalBattery.value} V`
        },
        
        {
            //Battery
            name: "internal_battery",
            visible: internalBattery.valid,
            icon: powerSupply.value ? icons.battery.battery_charge : getBatteryIcon(internalBattery.value),
            label: t("devices.battery"),
            value: powerSupply.value ? t(`devices.${externalPowerText(props.device.modelspecificinfo?.id)}`) : `${internalBattery.value}%`
        },
        {
            //Distance
            name: "distance",
            visible: distance.valid,
            icon: icons.distance,
            label: t("devices.distance_today"),
            value: `${distance.value} km`
        },
        {
            //Temperature
            name: "temperature",
            visible: temperature.valid,
            icon: icons.temperature,
            label: t("devices.temperature"),
            value: `${temperature.value}${props.device.modelspecificinfo.temp_hum?.currentsettings.tempType === 1 ? "°F" : "°C"}`
        },
        {
            //Humidity
            name: "humitidty",
            visible: humidity.valid,
            icon: icons.humidity,
            label: t("devices.humidity"),
            value: `${humidity.value}%`
        },
        {
            //Altitude
            name: "altitude",
            visible: altitude.valid,
            icon: icons.back,
            label: t("devices.altitude"),
            value: `${altitude.value}`
        },
        {
            //Ignition
            name: "ignition",
            visible: ignition.valid,
            icon: icons.volt,
            label: t("devices.ignition"),
            value: `${ignition.info ? t("devices.on") : t("devices.off")}`
        },
        {
            name: "dataplan",
            visible: dataplan.valid,
            icon: icons.sidebar.card_active,
            label: t("devices.dataplan"),
            value: `${dataplan.value ? t("devices.on") : t("devices.off")}`
        }
    ]


        return (
            <>
                <div className={`detail_container pb-3`}>
                    <div className="row m-0 p-0">
                        <div className="col-12 mt-2 text-center">
                            <img onLoad={() => setImgLoaded(true)} src={props.device.modelspecificinfo.modelphoto} className="w-50" alt="..."/>
                        </div>
                    </div>
                    {/* TINY CARDS */}
                    <div className="row align-items-center m-0 p-0 pb-2">
                        <div className="col-1 m-0 p-0 ps-2">
                            <img className='primary-icon size_20' src={icons.info_icon} alt="..." />
                        </div>
                        <div className="col-11 m-0 p-0 ps-1">
                            <p className="primary bold uppercase">{t("devices.info")}</p>
                        </div>
                    </div>
                    <div className="row align-items-center m-0 p-0 mb-3">
                        <div className="col-1 m-0 p-0 ps-2" />
                        <div className="col-11 m-0 p-0 ps-1">
                            <div className="row  m-0 p-0">
                                {visibleCards.map((card, index) => (
                                    card.visible &&
                                    <DeviceInfoCard key={index} name={card.name} index={index} value={card.value} label={card.label} icon={card.icon}/>
                                ))}
                            </div>
                        </div>
                    </div>
                    {/* ALARM CENTRAL */}
                    {alarmCentral.valid &&
                        <div className="mb-3">
                            <div className="row align-items-center m-0 p-0">
                                <div className="col-1 m-0 p-0 ps-2">
                                    {/* MISSING */}
                                    <img className='primary-icon size_20' src={icons.central} alt="..." />
                                </div>
                                <div className="col-11 m-0 p-0 ps-1">
                                    <p className="primary bold uppercase">{t("devices.alarm_central_arm")}</p>
                                </div>
                            </div>
                            <div className="row align-items-center m-0 p-0">
                                <div className="col-1 m-0 p-0 ps-2" />
                                <div className="col-11 m-0 p-0 ps-1">
                                    <div className="w-100 pe-3" style={{height: "40px"}}>{renderAlarmSwitch(alarmCentral.value)}</div>
                                </div>
                            </div>
                        </div>
                    }
                    {/* POWER SWITCH */}
                    {relay.valid &&
                        <div className="mb-3">
                            <div className="row align-items-center m-0 p-0">
                                <div className="col-1 m-0 p-0 ps-2">
                                    <img className='primary-icon size_20' src={icons.relay} alt="..." />
                                </div>
                                <div className="col-11 m-0 p-0  ps-1">
                                    <p className="primary bold uppercase">{t("devices.power_switch")}</p>
                                </div>
                            </div>
                            <div className="row align-items-center m-0 p-0">
                                <div className="col-1 m-0 p-0 ps-2" />
                                <div className="col-11 m-0 p-0  ps-1">
                                <div className="w-100 pe-3" style={{height: "40px"}}>{renderPowerSwitchSegment(relay.pending.status ? relay.pending.value : relay.value)}</div>
                                </div>
                            </div>
                        </div>
                    }
                    {/* LOCATION HISTORY */}
                    {location.valid &&
                    <>
                        <div className="row align-items-center m-0 p-0">
                            <div className="col-1 m-0 p-0 ps-2">
                                <img className='primary-icon size_20' src={icons.sidebar.history_active} alt="..." />
                            </div>
                            <div className="col-11 m-0 p-0 ps-1">
                                <p className="primary bold uppercase">{t("devices.location_history")}</p>
                            </div>
                        </div>
                        <div className="row align-items-center m-0 p-0">
                            <div className="col-1 m-0 p-0 ps-2" />
                            <div className="col-11 m-0 p-0 ps-1">
                                <div className="w-100 pe-3" style={{height: "40px"}}>{renderLocationHistory()}</div>
                            </div>
                        </div>
                    </>
                    }

                </div>
            </>
        )
    }

    const toggleRelay = (event, id) => {
        event.stopPropagation();
        props.setDeviceToggle(id)
    }

    useEffect(() => {
        setToggleActive(props.device.selected)
    }, [props.device.selected])

    const getBatteryIcon = (value) => {
        if (value > 95) {
            return icons.battery_full;
        } else if (value > 70) {
            return icons.battery_three_quarter;
        } else if (value > 40) {
            return icons.battery_half;
        } else if (value > 20) {
            return icons.battery_quarter;
        } else {
            return icons.battery_empty;
        }
    }

    useEffect(() => {
        if(props.deviceListLength === 1){
            props.setCurrentDevice(props.device.id)
            // toggleDetail(props.device.id)
        }
      }, [props.deviceListLength])

    const getUpdateStatus = () => {
        if(props.device.status === "online"){
            return(
                <>
                <span className={`dot bg-online`}/>
                <p className="ps-2">{t("devices.now")}</p>
                </>
            )
        }else if(props.device.lastupdate){
            return(
                <>
                <span className={`dot bg-offline`}/>
                <p className="ps-2">{fixDate(props.device.lastupdate, false, true)}</p>
                </>
            )
        }else{
            return(
                <>
                <span className={`dot bg-neverconnected`}/>
                <p className="ps-2">{t("devices.never_connected")}</p>
                </>
            )
        }
    }
    
    return(
        <>
            <div className={`row m-0 cursor-pointer ps-3 py-3 ${props.currentDevice === props.device.id ? "bg-primary" : props.index % 2 === 0 ? "bg-white primary" : "bg-light-grey primary shadow-inner"}`} onClick={() => props.setCurrentDevice(props.currentDevice === props.device.id ? -1 : props.device.id)}>
                <div className="col-4 m-0 pe-1 p-0 text-truncate d-flex align-items-center">
                    <div className={`me-3 ${!props.checkboxVisibility ? "d-none" : toggleActive ? "bg-checked" : "bg-grey"} size_20 border-sm d-flex justify-content-center p-1`} onClick={(event) => toggleRelay(event, props.device)}>
                        {<img className={`"white-icon" ${toggleActive ? "" : "opacity-0"}`} src={icons.check} alt={""} />}
                    </div>                    
                    <p className="bold text-truncate">{props.device.name}</p>
                </div>
                <div className="p-0 m-0 col-8">
                    <div className={`row d-flex justify-content-around m-0 p-0 align-items-center`}>
                        <div className="col-7 p-0 justify-content-around">
                            <div className="d-flex flex-row align-items-center">
                                {getUpdateStatus()}
                            </div>
                        
                        </div>
                        <div className="col-3 m-0 p-0">
                            <img src={getBatteryIcon(props.device.modelspecificinfo.internalbattery.internalbatteryinfo)} alt={""}/>
                        </div>
                        <div className={`d-flex flex-column justify-content-center col-2 m-0 p-0 ${props.device.latitude ? "cursor-pointer" : "opacity-0"}`} onClick={(event) => showOnMap(event, props.device)}>
                            <img className=" m-0 align-self-center" src={props.device.latitude ? icons.location_marker_online : icons.location_marker_offline} alt={""}/>
                        </div>

                    </div>

                </div>
            </div>
            <Collapse in={props.currentDevice === props.device.id && imgLoaded}>
                {FLAVOR === "tracking" ? renderDeviceDetail() : FLAVOR === "bike" ? renderBikeDetail() : FLAVOR === "alco" ? renderAlcoDetail() : null}
            </Collapse>
            <ZoneModal show={showZoneModal} activateAlarm={() => toggleAlarm(true)} device={props.device} onClose={() => setShowZoneModal(false)} />
            
        </>
    )
}

export default DeviceRowMobile;