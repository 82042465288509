import React, { useState, useEffect } from 'react';
import { icons } from "../../helpers/constants";
import { useTranslation } from "react-i18next";
import * as request from "../../services/fetchService";
import { downloadPDF } from '../../helpers/helperFunctions';

const Manuals = (props) => {
	const { i18n, t } = useTranslation();
    const [manualList, setManualList] = useState()

    useEffect(() => {
        getManuals()
    }, [])


    useEffect(() => {
		// This function will be called whenever the language changes
		const onLanguageChanged = () => {
            getManuals()
		//   getEventsPost(currentPage, currentFilter, currentTypes, currentDevices)

		  // You can perform other actions here when the language changes
		};
	
		// Register the event listener for language changes
		i18n.on('languageChanged', onLanguageChanged);
	
		// Cleanup function to remove the event listener
		return () => {
		  i18n.off('languageChanged', onLanguageChanged);
		};
	  }, [i18n]); // Dependency array to ensure effect is set up correctly


    const getManuals = () => {
        let lang = localStorage.getItem("language")
        request.post("", "services/vehiclemanuals", {language: lang ? lang : "en"}).then((response) => {
           setManualList(response.data.manuals)
        })  
    }

    
    return(
        <>
        <p className="thin mb-1">{t("settings.user_manuals")}</p>
        <div className="d-flex flex-column shadow-small settings-radius">
            {manualList && manualList.map((manual, index) => (
                <div key={index} className={`p-3 d-flex justify-content-between align-items-center bg-white ${index < manualList.length-1 ? "border-settings" : ""}`}>
                    <h4 className="regular">{manual.productname}</h4>
                    <img className="size_15 cursor-pointer" src={icons.download} alt={""} onClick={() => downloadPDF(manual.pdfurl)}/>
                </div>

            ))}     
        </div>
        </>
    )
}

export default Manuals;