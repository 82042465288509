import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { checkToken,  } from '../helpers/helperFunctions';
import { logos, icons, MAIL_FORMATTING } from "../helpers/constants";
import Button from '../components/Buttons/Button';
import LanguagePicker from '../components/LanguagePicker';
import * as request from "../services/fetchService";
import { useNavigate } from 'react-router-dom';
import { useIsMobile } from '../helpers/customHooks';
import { useContextData } from '../components/ContextData'; // Adjust the path as necessary
import { useParams } from "react-router";
import toast from 'react-hot-toast';


const ResetPassword = (props) => {
    let params = useParams();

    const MOBILE = useIsMobile();
    const navigate = useNavigate()
	const { t } = useTranslation();
    const [email, setEmail] = useState("")
    const [invalidEmail, setInvalidEmail] = useState(false)
    const [errorEmail, setErrorEmail] = useState("")
    const [password, setPassword] = useState("")
    const [passwordConfirm, setPasswordConfirm] = useState("")
    const [errorPassword, setErrorPassword] = useState("")
    const [errors, setErrors] = useState({})
    const token = params.token

    const handleChange = (type, e) => {
        setErrors({})
        if(type === "email"){
            if(MAIL_FORMATTING.test(e.target.value)){
                setInvalidEmail(false)
            }else{
                setInvalidEmail(true)
            }
            setEmail(e.target.value)
        }else if(type === "password"){
            setPassword(e.target.value)
        }else if(type === "passwordConfirm"){
            setPasswordConfirm(e.target.value)
        }
    }

    const validateInputs = () => {
        let innerErrors = {}
        innerErrors = {
            email: !MAIL_FORMATTING.test(email),
            password: password.length<6,
            password_confirm: passwordConfirm.length<6,
            password_mismatch: password !== passwordConfirm
        }

        setErrors(innerErrors)
        return innerErrors
    }

    const resetPass = (e) => {
        e.preventDefault();

        const hasErrors = validateInputs()
        if(Object.values(hasErrors).some(value => value === true)){
            return
        }else{
            let obj = {
                email: email,
                token: token,
                newpassword: password,
                newpassword2: passwordConfirm,
                language: localStorage.getItem("language") ? localStorage.getItem("language") : "en" 
            }
            request.post("", "forgotpassword/newpassword", obj).then((response) => {
                if(response.data.error){
                    toast.error(t("toast.error"))
                }else{
                    toast.success(t("toast.success"))
                    navigate("/login")
                }
            });
        }

    }
    
    return(
        <>   
        <div className="container-fluid h-100">
            <div className="d-flex justify-content-center h-100">
                <div className={`d-flex flex-column justify-content-center ${MOBILE ? "w-100 px-4" : "w-25"} dvh-100`}>
                <Button className="align-self-start" icon={icons.back} notext={true} onClick={() => navigate("/login")}/>
                <img className="login_logo mx-auto mb-5" src={logos.big} alt="logo"/>
                <h1>{t("login.reset_password")}</h1>
                <p>{t("login.reset_password_sub")}</p>
                <form onSubmit={(e) => resetPass(e)}>
                    <div className="form-floating form-floating-group flex-grow-1 is-valid mb-4">
                        <input
                            type="email"
                            placeholder="0"
                            id="email"
                            className="form-control login-input-field pe-4"
                            name="email"
                            value={email}
                            onChange={(e) => {
                                handleChange("email", e);
                            }}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                e.preventDefault();// Prevent the default Enter key behavior
                                document.getElementById("password").focus()

                                }
                            }}
                        />
                        <img className={`position-absolute end-0 bottom-0 mb-2 ${email === "" ? "d-none" : ""}`} src={invalidEmail ? icons.cross : icons.check} alt=""/>
                        <label htmlFor="floatingInput">
                            {t("login.email_placeholder")}
                        </label>
                        <div className="invalid-feedback ms-1 bottom">
                            {errors.email ? t("signup.invalid_email") : ""}
                        </div>
                    </div>

                    <div className="form-floating form-floating-group flex-grow-1 is-valid mb-4">
                    <input
                        type="password"
                        placeholder="0"
                        id="password"
                        className="form-control login-input-field pe-4"
                        name="password"
                        value={password}
                        onChange={(e) => {
                            handleChange("password", e);
                        }}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                            e.preventDefault();// Prevent the default Enter key behavior
                            document.getElementById("passwordConfirm").focus()
                            }
                        }}
                    />
                    <img className={`position-absolute end-0 bottom-0 mb-2 ${password === "" ? "d-none" : ""}`} src={password.length<6 ? icons.cross : icons.check} alt=""/>
                    <label htmlFor="floatingInput">
                        {t("signup.password")}
                    </label>
                    <div className="invalid-feedback ms-1 bottom">
                        {errors.password ? t("signup.invalid_password") : ""}
                    </div>
                </div>
                <div className="form-floating form-floating-group flex-grow-1 is-valid mb-5">
                    <input
                        type="password"
                        placeholder="0"
                        id="passwordConfirm"
                        className="form-control login-input-field pe-4"
                        name="passwordConfirm"
                        value={passwordConfirm}
                        onChange={(e) => {
                            handleChange("passwordConfirm", e);
                        }}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                            e.preventDefault();// Prevent the default Enter key behavior
                            resetPass(e)
                            }
                        }}
                    />
                    <img className={`position-absolute end-0 bottom-0 mb-2 ${passwordConfirm === "" ? "d-none" : ""}`} src={password.length<6 || (password !== passwordConfirm) ? icons.cross : icons.check} alt=""/>
                    <label htmlFor="floatingInput">
                        {t("signup.password_confirm")}
                    </label>
                    <div className="invalid-feedback ms-1 bottom">
                        {errors.password_confirm ? t("signup.invalid_password") : errors.password_mismatch ? t("signup.password_mismatch") : ""}
                    </div>
                </div>

                    <Button btnClass="btn-primary w-100" onClick={(e) => resetPass(e)}>{t("login.reset_password")}</Button>
                </form>
            </div>
        </div>
        </div>
        <div className={`position-absolute left-0 bottom-0 ${MOBILE ? "ms-2 mb-2" : "ms-5 mb-5"} bg-grey cursor-pointer rounded`}>
            <LanguagePicker />
        </div>
        </>
    )
}

export default ResetPassword;