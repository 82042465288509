import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { checkToken,  } from '../helpers/helperFunctions';
import { logos, icons, MAIL_FORMATTING } from "../helpers/constants";
import Button from '../components/Buttons/Button';
import LanguagePicker from '../components/LanguagePicker';
import * as request from "../services/fetchService";
import { useNavigate } from 'react-router-dom';
import { useIsMobile } from '../helpers/customHooks';
import { useContextData } from '../components/ContextData'; // Adjust the path as necessary
import toast from 'react-hot-toast';


const ForgotPassword = (props) => {
    const MOBILE = useIsMobile();
    const { accessToken, setAccessToken } = useContextData();
    const navigate = useNavigate()
	const { t } = useTranslation();
    const [email, setEmail] = useState("")
    const [invalidEmail, setInvalidEmail] = useState(false)
    const [errorEmail, setErrorEmail] = useState("")
    const [password, setPassword] = useState("")
    const [errorPassword, setErrorPassword] = useState("")
    
    
    const handleChange = (type, e) => {
        if(type === "email"){
            setErrorEmail("")
            if(MAIL_FORMATTING.test(e.target.value)){
                setInvalidEmail(false)
            }else{
                setInvalidEmail(true)
            }
            setEmail(e.target.value)
        }
    }

    const resetPass = (e) => {
        e.preventDefault();
        if (email === "" || invalidEmail) {
            setErrorEmail(t("login.invalid_email"));
            return;
        }
        let obj = {
            email: email,
            language: localStorage.getItem("language") ? localStorage.getItem("language") : "en" 
        }
        request.post("", "forgotpassword/sendresetlink", obj).then((response) => {
            if(response.data.successful){
                toast.success(t("toast.success"))
            }else{
                toast.error(response.data.message)
            }
		});
    }
    
    return(
        <>   
        <div className="container-fluid h-100">
            <div className="d-flex justify-content-center h-100">
                <div className={`d-flex flex-column col-10 col-md-6 col-xl-4 loginfield`}>
                <div className="d-flex position-relative">
                    <Button className="position-absolute my-auto" icon={icons.back} notext={true} onClick={() => navigate("/login")}/>
                    <img className="login_logo mx-auto mb-5" src={logos.big} alt="logo"/>
                </div>
                <h1>{t("login.forgot_password")}</h1>
                <p>{t("login.forgot_password_sub")}</p>
                <form onSubmit={(e) => resetPass(e)}>
                    <div className="form-floating form-floating-group flex-grow-1 is-valid mb-5">
                        <input
                            type="email"
                            placeholder="0"
                            id="email"
                            className="form-control login-input-field pe-4"
                            name="email"
                            value={email}
                            onChange={(e) => {
                                handleChange("email", e);
                            }}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                e.preventDefault();// Prevent the default Enter key behavior
                                resetPass(e)
                                }
                            }}
                        />
                        <img className={`position-absolute end-0 bottom-0 mb-2 ${email === "" ? "d-none" : ""}`} src={invalidEmail ? icons.cross : icons.check} alt=""/>
                        <label htmlFor="floatingInput">
                            {t("login.email_placeholder")}
                        </label>
                        <div className="invalid-feedback ms-1 bottom">
                            {errorEmail === "" ? "" : errorEmail}
                        </div>
                    </div>
                    <Button btnClass="btn-primary w-100" onClick={(e) => resetPass(e)}>{t("login.reset_password")}</Button>
                </form>
            </div>
        </div>
        </div>
        <div className={`position-absolute left-0 bottom-0 ${MOBILE ? "ms-2 mb-2" : "ms-5 mb-5"} bg-grey cursor-pointer rounded`}>
            <LanguagePicker />
        </div>
        </>
    )
}

export default ForgotPassword;