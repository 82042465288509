import React, { useState, useEffect } from 'react';
import { INDEX_URL, GOOGLE_MAPS_KEY, icons, FLAVOR } from "../../helpers/constants";
import { fixDate, getBatteryIcon } from "../../helpers/helperFunctions";
import SegmentedControl from '../SegmentedControl/SegmentedControl';
import TextField from "@mui/material/TextField";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs'; // Import dayjs
import 'dayjs/locale/sv';
import { MapTogglesProvider, useMapToggles } from '../Map/ToggleContext'; // Adjust the path as necessary
import { useNavigate } from 'react-router-dom';
import * as request from "../../services/fetchService";
import { useTranslation } from "react-i18next";
const OpenMessage = (props) => {
    const [message, setMessage] = useState({})
    const [quill, setQuill] = useState()
    
    useEffect(() => {
        setMessage(props.message)
    },[props])

    useEffect(() => {
        const loadQuillScript = () => {
          const scriptElement = document.createElement('script');
          scriptElement.id = 'quilly';
          scriptElement.src =
            'https://cdn.jsdelivr.net/npm/quill@2.0.0-rc.2/dist/quill.js';
          scriptElement.async = true;
          scriptElement.onload = createQuill;
          document.body.appendChild(scriptElement);
        };
    
        // Check if Quill script is already loaded
        const quillScript = document.querySelector('#quilly');
        if (!quillScript) {
          loadQuillScript();
        } else {
          createQuill();
        }
      }, []);
    
    
      const createQuill = () => {
        // Check if Quill is already initialized
        if (quill) return;
    
        // Create Quill instance
        setQuill(new window.Quill('#editor'));
        // quill = new window.Quill('#editor');
      };

      useEffect(() => {
        // Update Quill editor content when message changes
        if (message.content && quill) {
            let content = JSON.parse(message.content)
            quill.setContents(content.ops);
            quill.enable(false)
        }
      }, [message.content, quill]);

    
    return(
        <>
            <div className="p-3">
                <div className="w-100 h-100" id="editor"/>
            </div>
        </>
    )
}

export default OpenMessage;