import React, { useState, useEffect } from 'react';
import { INDEX_URL, CUST, icons, FLAVOR } from "../../helpers/constants";
import { useTranslation } from "react-i18next";
import * as request from "../../services/fetchService";
import { fixDate } from '../../helpers/helperFunctions';
import Button from '../Buttons/Button';
import { Collapse } from '@mui/material';
import ContactForm from './ContactForm';
import { useIsMobile } from '../../helpers/customHooks';
import ShowLocationsModal from '../Modals/ShowLocationsModal';

const Support = (props) => {
	const { i18n, t } = useTranslation();
    const MOBILE = useIsMobile();
    const [faqData, setFaqData] = useState()
    const [faqVisible, setFaqVisible] = useState(false)
    const [currFaq, setCurrFaq] = useState(-1)
    const [locationsVisible, setLocationsVisible] = useState(false)
    const [locations, setLocations] = useState([])
    const [showLocationsList, setShowLocationsList] = useState(false)
    const [userLocation, setUserLocation] = useState({ lat: null, lng: null });
    const [showModal, setShowModal] = useState(false)
    const [clickedAddress, setClickedAddress] = useState("")
    const [externalLinksVisible, setExternalLinksVisible] = useState(false)

    useEffect(() => {
        getFAQ()
        if(FLAVOR === "bike"){
            getServiceLocations()
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition((position) => {
                    setUserLocation({
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    });
                });
            }
        }
    }, [])


    useEffect(() => {
		// This function will be called whenever the language changes
		const onLanguageChanged = () => {
            getFAQ()
		};
		// Register the event listener for language changes
		i18n.on('languageChanged', onLanguageChanged);
	
		// Cleanup function to remove the event listener
		return () => {
		  i18n.off('languageChanged', onLanguageChanged);
		};
	  }, [i18n]); // Dependency array to ensure effect is set up correctly

    const getFAQ = () => {
        let lang = localStorage.getItem("language")
        request.post("", "services/faq", {language: lang ? lang : "en"}).then((response) => {
            if(response.data.successful){
                setFaqData(response.data.faq)
                setFaqVisible(response.data.valid)
        }

        })  
    }

    const getServiceLocations = () => {
        request.get("services/locations").then((response) => {
            if(response.data.successful){
                setLocationsVisible(response.data.valid)
                setLocations(response.data.servicelocations)
            }
        })  
    }

    const openFaq = (index) => {
        if(currFaq === index){
            setCurrFaq(-1)
        }else{
            setCurrFaq(index)
        }
    }
    const haversineDistance = (lat1, lon1, lat2, lon2) => {
        const R = 6371; // Radius of the Earth in km
        const dLat = (lat2 - lat1) * (Math.PI / 180);
        const dLon = (lon2 - lon1) * (Math.PI / 180);
        const a =
          Math.sin(dLat / 2) * Math.sin(dLat / 2) +
          Math.cos(lat1 * (Math.PI / 180)) * Math.cos(lat2 * (Math.PI / 180)) *
          Math.sin(dLon / 2) * Math.sin(dLon / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const distance = R * c;
        return distance;
    };

    useEffect(() => {
        if(!showModal){
            setClickedAddress()
        }
    }, [showModal])

    const handleModal = (address) => {
        setClickedAddress(address)
        setShowModal(!showModal)
    }

    const openExternalLink = () => {
        const url = "https://www.google.com"
        window.open(url, "_blank")
    }

    return(
        <>
        <p className="thin">{t("settings.support")}</p>
        {!showLocationsList 
            ? 
            <>
            {faqVisible &&
                <div className={`d-flex flex-column mt-3`}>
                    <h3 className='primary mb-1'>{t("settings.faq")}</h3>
                    {faqData && faqData.map((faq, index) => (
                        <div key={index} className={`d-flex flex-column shadow-small settings-radius cursor-pointer  ${index !== faqData.length - 1 ? 'mb-3' : ''}`} onClick={() => openFaq(index)}>
                            <div className={`p-3 d-flex justify-content-between bg-white align-items-center`}>
                                <h4 className='primary'>{faq.headline}</h4>
                                <img className={`size_15 ${index === currFaq ? "" : "rotate-180"}`} src={icons.down_arrow_dark} alt={""} />
                            </div>
                            <Collapse in={index === currFaq} className="shadow-small primary">
                                <div className="bg-white px-3 pb-3">
                                    {faq.body.split('\n').map((text, index) => (<p key={index}>{text}<br/></p>))}
                                </div>
                            </Collapse>
                        </div>
                    ))}
                   </div> 
            }

            {locationsVisible &&
            <div className={`${faqVisible ? "mt-3" : ""}`}>
                <h2>{t("settings.service_locations")}</h2>
                <div 
                    onClick={() => setShowLocationsList(true)}
                    className={`p-3 d-flex justify-content-between shadow-small settings-radius align-items-center cursor-pointer ${""} ${"bg-white"}`}>
                    <h4 className="regular">{t("settings.find_service_locations")}</h4>
                    <img className="size_15" src={icons.right_arrow_dark} alt={""} />
                </div>
            </div>
            }
            {externalLinksVisible &&
            <div className={`${locationsVisible ? "mt-3" : ""}`}>
                <h2>{t("settings.external_links")}</h2>
                <div 
                    onClick={() => openExternalLink()}
                    className={`p-3 d-flex justify-content-between shadow-small settings-radius align-items-center cursor-pointer ${""} ${"bg-white"}`}>
                    <h4 className="regular">{t("settings.support")}</h4>
                    <img className="size_15" src={icons.right_arrow_dark} alt={""} />
                </div>
            </div>
            }
            <ContactForm />
            </>
            :
            <div className="my-3">
                {!MOBILE && <Button className="align-self-start" icon={icons.back} notext={true} onClick={() => setShowLocationsList(false)}/>}
                <div className="d-flex flex-column shadow-small settings-radius mt-3">
                    {locations.map((item, index) => (
                        <div key={index} className={`p-3 d-flex justify-content-between bg-white cursor-pointer ${index < locations.length-1 ? "border-settings" : ""}`}  onClick={() => handleModal(item.address)}>
                            <div className="d-flex flex-column">
                                <h4 className={`bold`}>{item.name}</h4>
                                <p>{item.address}</p>
                            </div>
                            <div className={`d-flex flex-column align-items-center`}>
                                <img className="size_25" src={icons.marker} alt={""} />
                                <p className={`${userLocation.lat && userLocation.lng ? "" : "d-none"}`}>
                                    {`${haversineDistance(userLocation.lat,userLocation.lng,item.latitude,item.longitude).toFixed(0)} km`}
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        }
        <ShowLocationsModal show={showModal} address={clickedAddress} onClose={() => setShowModal(!showModal)}/>
        </>
    )
}

export default Support;